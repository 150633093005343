export const messages = {
  UNEXPECTED_ERROR: 'Erro inesperado, contacte o administrador do sistema',
  USER_NOT_FOUND: 'Usuário não encontrado',
  NOT_FOUND: 'Não encontrado',
  CAST_ERROR: 'Erro ao converter',
  RECEIVE_NOT_FOUND: 'Destinatário não encontrado',
  SENDER_NOT_FOUND: 'Remetente não encontrado',
  EMPTY_REQUIRED_FIELD: 'Campo obrigatório',
  CNPJ_INVALID: 'CNPJ inválido',
  PHONE_INVALID: 'Telefone inválido',
  EMAIL_INVALID: 'E-mail inválido',
  INVALID_REQUEST: 'Requisição inválida',
  CPF_INVALID: 'CPF inválido',
  POSTO_NOT_FOUND: 'Posto Autorizado não encontrado',
  ERROR_CONVERT_FILE: 'Erro ao converter arquivo',
  NOT_PERMITED: 'Acesso não permitido',
  INVALID_CONFIRM_PASSWORD: 'As senhas informadas não correspondem',
  INVALID_CONFIRM_EMAIL: 'Os e-mails informados não correspondem',
  CNPJ_AREADY_REGISTERED: 'CNPJ já cadastrado',
  DOCUMENT_INVALID: 'Documento inválido',
  INVALID_INSCRICAO_ESTADUAL: 'Inscrição estadual inválida',
  CEP_INVALID: 'CEP inválido',
  CPF_AREADY_REGISTERED: 'Cpf já cadastrado',
  INVALID_CPF:'CPF inválido',
  ConfirmEqualsConstraint: 'As senhas informadas não correspondem',
  EMAIL_AREADY_REGISTERED: 'E-mail já cadastrado',
  NAO_TEM_ESTABELECIMENTO_COOPERADO: "O cooperado não atende neste consultório",
  NAO_TEM_ESTABELECIMENTO_COOPERADO_CONVENIO: "O cooperado não atende este convênio neste consultório",
  DT_NASCIMENTO_MENOR_18: 'Idade menor que 18 anos',
  PERFIL_COOPERADO_NAO_SELECIONADO: "É necessário selecionar pelo menos um Cooperado para ser notificado",
  PERFIL_AUDITORES_NAO_SELECIONADO: "É necessário selecionar pelo menos um auditor para ser notificado",
  NENHUM_PERFIL_SELECIONADO: "É necessário selecionar pelo menos um perfil para ser notificado",
  ERRO_CRIAR_PENDENCIA: "Não foi possível criar pendência.",
  ERRO_CRIAR_PENDENCIA_ENVIO_EMAIL: "Não foi possível enviar o e-mail porém a pendência foi criada com sucesso.",
  ERRO_CRIAR_REGRAS_CONVENIO: "Não foi possível salvar regras de convenio.",
  ERRO_CRIAR_CRONOGRAMA_CONVENIO: "Não foi possível salvar cronograma de faturamento para o convenio.",
  MAX_ATENDIMENTO_LOTE: "O Lote só pode conter no máximo 100 atendimentos.",
  NAO_TEM_EXECUTANTES_PROCEDIMENTO: "Não existe Identificação do(s) Profissional(is) Executante(s) para este procedimento ",
  CPF_CNPJ_INVALID:"CPF OU CNPJ inválido",
  CPF_CNPJ_EM_USO:"CPF ou CNPJ já cadastrado",
  NUMERO_ATENDIMENTO_JA_CADASTRADO:"Já existe atendimento com o número informado",
  NUMERO_GUIA_JA_CADASTRADO:"Já existe atendimento com o número informado",
  COOPERADO_JA_PERTENCE_CONSULTORIO:"Cooperado já foi incluso ao Consultório",
  QUANTIDADE_AUTORIZADO_DIFERENTE_EXECUCAO:"Quantidade autorizada está diferente da somatorio de Dados da Execução",
  MAIS_UM_TIPO_GUIA:"Para gerar protocolo é necessario selecionar somente um Tipo de Guia",
  CONVENIO_COM_ATENDIMENTO:"Não foi possível excluir o convênio, pois o mesmo já foi utilizado em outro registro",
  CPF_CNPJ_ENDERECO_DUPLICADO:"Não foi possível salvar o cadastro, pois já existe um consultório com o mesmo CPF/CNPJ e endereço cadastrado!",
  ERRO_NOME_DO_BENEFICIARIO: "Não é possível digitar somente números no campo nome do beneficiário",
  DATA_ATENDIMENTO_INCORRETA:"Não é possivel salvar atendimentos com mais de 45 dias.",
  INVALID_NUMERO:"Número invalido",
  PROCEDIMENTO_NAO_ACEITO_GUIA:"Procedimento não aceito para o tipo de guia.",
  DATA_NASCIMENTO_BENEFICIARIO: "Data Nascimento Obrigatório.",
  CPF_OBRIGATORIO:"CPF Obrigatório",
  ANEXO_ENCAMINHADO:"Obrigatório informar se a Guia Fisica já foi enviada."
};

export function translate(key) {
  return messages[key];
}
