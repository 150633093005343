//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";


export const faturamentoParticularService = {
    salvar,
    findFormaPagamento,
    findFaturamentoByAtendimentoId
}

function salvar(input) {
    return defaultService.doPost(`${urlsConstants.FATURAMENTO_PARTICULAR}salvar`, input);
}

function findFormaPagamento(){
    return defaultService.doGet(`${urlsConstants.FATURAMENTO_PARTICULAR}findFormaPagamento`);
}

function findFaturamentoByAtendimentoId(atendimentoId){
    return defaultService.doGet(`${urlsConstants.FATURAMENTO_PARTICULAR}findByAtendimento/${atendimentoId}`);
}