//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const controleIntegracaoService = {
  save
};

function save(input) {
    return defaultService.doPost(`${urlsConstants.CONTROLE_INTEGRACAO}save`, input);
}
