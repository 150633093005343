/* eslint-disable */
import React, { useRef } from "react";
import { formatterHelper } from "../../helpers/formatter.helper";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import _ from "lodash";

const InputViewEdit = ({
  component: Component,
  viewMode,
  modelo,
  ...nextProps
}) => {
  let visiable = true;
  if (modelo.length > 0) {
    let field = _.find(modelo, { label: nextProps.label });
    if (field) {
      visiable = field.visiable;
    }
  }
  let value;
  if (!nextProps.defaultValue) {
    value = formatterHelper.getNameOrHifen(
      nextProps.value,
      nextProps.defaultValue
    );
  } else {
    value = nextProps.defaultValue;
  }

  const outerRef = useRef(null);


  const handleCopyValue = event => {
    var textArea = document.createElement("textarea");
    textArea.value = nextProps.defaultValue || nextProps.value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  let menu = [
    { label: "Copiar", icon: "icon-copy", callback: handleCopyValue, legend: 'Ctrlr+C' }
  ];


  return (
    <React.Fragment>
      {/* <CustomContext outerRef={outerRef} items={menu}></CustomContext> */}
      {visiable && (
        <React.Fragment>
          {!viewMode && <Component {...nextProps} />}
          {viewMode && (
            <React.Fragment>
              {" "}
              {nextProps.label && (
                <React.Fragment>
                  <Label for={nextProps.name}>{nextProps.label}</Label>{" "}
                </React.Fragment>
              )}
              <p
                className={`form-view ${nextProps.viewClassName ? nextProps.viewClassName : ""
                  }`}
                ref={outerRef}
              >
                <React.Fragment >{value}</React.Fragment>
              </p>

            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  const { modelo } = state.modeloVisualizacao;

  return {
    modelo
  };
}

export default connect(mapStateToProps, null)(InputViewEdit);
