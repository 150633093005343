import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../../components/FormGroup";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import FormTextArea from "../../../../components/Inputs/FormTextArea";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import { dateHelper } from "../../../../helpers/date.helper";
import { formatterHelper } from "../../../../helpers/formatter.helper";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import AtendimentoAnexos from "../../../../components/Inputs/AtendimentoAnexos";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { objectsConstants } from "../../../../constants/objects.constants";
import CheckBoxInput from "../../../../components/Inputs/CheckBoxInput";

class ValoresProcedimentosGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
    };
  }


  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  getValorProcedimentos = () => {
    const { values } = this.props;
    let totalProcedimentos = this.getValorProcedimento(values);

    return formatterHelper.formatMoney(totalProcedimentos, 2, ",", ".");
  };
  getValorProcedimento(values) {
    let totalProcedimentos = 0;
    _.map(values.atendimentoExecucaos, function (procedimento) {
      if (
        procedimento &&
        procedimento.valorUnitario &&
        procedimento.fator &&
        procedimento.quantAutorizada
      ) {
        let valorProcedimento =
          procedimento.valorUnitario *
          procedimento.fator *
          procedimento.quantAutorizada;
        totalProcedimentos += isNaN(valorProcedimento) ? 0 : valorProcedimento;
      }
    });
    return totalProcedimentos;
  }

  getTotalGeral = () => {
    const { values } = this.props;
    let totalProcedimentos = this.getValorProcedimento(values);
    totalProcedimentos += isNaN(values.totalTaxasAlugueis)
      ? 0
      : values.totalTaxasAlugueis;
    totalProcedimentos += isNaN(values.totalMateriais)
      ? 0
      : values.totalMateriais;
    totalProcedimentos += isNaN(values.totalCPME) ? 0 : values.totalCPME;
    totalProcedimentos += isNaN(values.totalMedicamentos)
      ? 0
      : values.totalMedicamentos;
    totalProcedimentos += isNaN(values.totalGasesMedicinais)
      ? 0
      : values.totalGasesMedicinais;
    return formatterHelper.formatMoney(totalProcedimentos, 2, ",", ".");
  };

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  //Verifica se a data é antes da implementacao do chamado de tipo do anexo.
  verificaDataAntesImplTipoAnexo = () => {
    const { values } = this.props;    
    if(values.id){
      const dataCadastro = new Date(values.dataCadastro);
      dataCadastro.setHours(0, 0, 0, 0);
      if(dataCadastro < objectsConstants.DATE_IMPL_GUIA_HONORARIO_ENVIO_ELETRONICO_AUTOMATICO){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  render() {
    const { handleChange, values, viewMode, setFieldValue, errors } = this.props;
    const dataAntesImplTipoAnexo = this.verificaDataAntesImplTipoAnexo();
    return (
      <React.Fragment>
        <div className="row section-form">
          <SelectableInput viewMode={viewMode} atendimento={values} className="anexoObservacaoHonorario"
            name={"observacao"}
            label={"Observação / Justificativa"}
            value={values.observacao}
          >
            <InputViewEdit
              noSize={true}
              id={"observacao"}
              label={"Observação / Justificativa"}
              component={FormTextArea}
              className={'textArea'}
              type={'textarea'}
              onChange={handleChange}
              name={`observacao`}
              placeholder=""
              viewMode={viewMode}
              value={values.observacao}
              alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <FormGroup className="fixo">
            <label>Valor total dos honorários</label>
            <p>{this.getValorProcedimentos()}</p>
          </FormGroup>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
            name={"dataEmissao"}
            label={"Data de emissão"}
            value={
              values.dataEmissao &&
              dateHelper.format(values.dataEmissao, { mode: "DATE" })
            }
          >
            <InputViewEdit
              label={"Data de emissão"}
              component={DatePickerInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              onBlur={() => this.sendToStorange(values)}
              defaultValue={
                values.dataEmissao
                  ? dateHelper.format(values.dataEmissao, { mode: "DATE" })
                  : "--"
              }
              mask={"99/99/9999"}
              name={"dataEmissao"}
              id={"dataEmissao"}
              placeholder=""
              type={"text"}
              viewMode={viewMode}
              value={values.dataEmissao}
              alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <FormGroup className="div-form-anexo mobile-100">
            <div className="">
              <InputViewEdit
                label="Anexo encaminhado fisicamente para a cooperativa?"
                component={SelectInput}
                options={objectsConstants.SIM_NAO}
                name={"anexoEncaminhado"}
                erroMensagem={errors.anexoEncaminhado}
                searchable={true}
                returnFullObject={true}
                defaultValue={values.anexoEncaminhado &&
                  values.anexoEncaminhado.value === false ? 'Não'
                  : formatterHelper.getLabelEnum(
                    values.anexoEncaminhado &&
                      values.anexoEncaminhado.value
                      ? values.anexoEncaminhado.value
                      : values.anexoEncaminhado,
                    objectsConstants.SIM_NAO
                  )}
                value={_.isBoolean(values.anexoEncaminhado) ? formatterHelper.getEnum(values.anexoEncaminhado, objectsConstants.SIM_NAO) : values.anexoEncaminhado}
                labelKey={"label"}
                valueKey={"value"}
                viewMode={viewMode}
                onChange={(name, value) => {
                  setFieldValue(name, value.value);
                }}
                onBlur={() => this.sendToStorange(values)}
                alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
              />
            </div>

            <div className="pt-3">
              <label>Anexos - Guia física</label>
            </div>
            <SelectableInput
              viewMode={viewMode}
              atendimento={values}
              className=""
              name={"atendimentoAnexos"}
              label={"Anexos - Guia física"}
              value={values.atendimentoAnexos}
            >
              <InputViewEdit
                component={AtendimentoAnexos}
                toggleAnexos={this.toggleAnexos}
                errors={errors}
                values={values}
                atualizaAnexos={this.atualizaAnexos}
                onChange={(name, anexos) => {
                  setFieldValue(name, anexos);
                }}
                openAnexo={this.state.openAnexo}
                user={this.props.user}
                anexosInputRefCallback={anexos => (this._anexosInput = anexos)}
                viewModeCustom={this.props.viewMode}
                dataAntesImplTipoAnexo={dataAntesImplTipoAnexo}
              />
            </SelectableInput>
            {values.cooperativa && values.cooperativa.permiteEnvioEletronico &&
              (!values.cooperativa.permiteEnvioEletronicoComAnexoGuiaHonorario || 
                dataAntesImplTipoAnexo)
              &&
              <div className="row section-form">
                <CheckBoxInput
                  labelClass={"font-13px verde-destaque font-bold"}
                  label={"Anexada guia com assinaturas do médico, paciente ou responsável e comprovantes"}
                  name={"envioEletronico"}
                  id={"envioEletronico"}
                  disabled={viewMode}
                  checked={values.envioEletronico}
                  errors={errors}
                  erroMensagem={errors.envioEletronico}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                /></div>
            }

            <div><label>{values.atendimentoAnexos
              && values.atendimentoAnexos.length > 0
              && values.atendimentoAnexos[0].midia.dataCadastro
              ? "Data de inclusão do anexo: " + formatterHelper.converterFormatoData(values.atendimentoAnexos[0].midia.dataCadastro) : ""}
            </label></div>
          </FormGroup>

        </div>
        <div className="row section-form">
          {values.observacaoSeparacaoGuia && viewMode && (<FormGroup className="mobile-100">
            <label>Observação guia com procedimento separado</label>


            <p>{values.observacaoSeparacaoGuia}</p>
          </FormGroup>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(ValoresProcedimentosGuiaForm));
