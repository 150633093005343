import {defaultService} from "./defaultService";
import {urlsConstants} from "../constants/url.constant";

export const regraProcedimnetoBloqueadosGuiaHospitalService = {
    getRegraProcedimnetoBloqueadosGuiaHospital
  };

function getRegraProcedimnetoBloqueadosGuiaHospital(idConvenio, idGuia, idHospital, idProcedimento){
    return defaultService.doGet(`${urlsConstants.REGRAS_PROCEDIMENTOS_BLOQUEADOS_GUIA_HOSPITAL}${idConvenio}/
        ${idGuia}/${idHospital}/${idProcedimento}`)

}