import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CascadeSelectInput from "../../components/Inputs/CascadeSelectInput";
import { objectsConstants } from "../../constants/objects.constants";
import iconAtendimento from "../../img/icon_atendimentos.svg";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";
import CheckBoxInput from "../../components/Inputs/CheckBoxInput";
import BuscaAtendimentoInput from "../../sascComponents/inputs/BuscaAtendimentoInput";
import { atendimentoService } from "../../services/atendimento.service";
import { ToolTipHelp } from "../../components/Utils/ToolTipHelp";
import { dateHelper } from "../../helpers/date.helper";
import { Modal, ModalBody } from "reactstrap";
import iconCriarLote from "../../img/icon_criarLote.svg";
import SelectInput from "../../components/Inputs/SelectInput";
import { userService } from "../../services/user.service";
class GuiaTopo extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      statusEdicaoEncaminhamento: [],
      toogleModalCriarProtocolo: false,
      convenioProtocolo: null,
    };
  }

  toogleModalCriarProtocolo = () => {
    this.setState({
      toogleModalCriarProtocolo: !this.state.toogleModalCriarProtocolo,
    });
  };

  validarQuantidadeAtendimentosGeracaoProtocolo() {
    let idsAtendimentos = this.props.rowsCheckedIdValue;
    if (
      objectsConstants.STATUS_ENCAMINHAMENTO.NOME ===
        this.state.filter.status.nome &&
      idsAtendimentos.length > 100
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderRightContent = () => {
    const { listaAtendimentos, atendimentoEdicao } = this.props;

    if (listaAtendimentos.length > 0) {
      if (listaAtendimentos.length === 1 && !listaAtendimentos[0].id) {
        return (
          <React.Fragment>
            <div className="px-5 m-auto ">
              <CheckBoxInput
                label={"Criar a guia com o passo a passo"}
                name={"criarPassoApasso"}
                id={"criarPassoApasso"}
                checked={this.props.user.criarGuiaPassoPasso}
                className="my-auto"
                onChange={(name, checked) => {
                  this.props.handleChangeCriarGuiaPassoPasso(checked);
                }}
              />
            </div>
          </React.Fragment>
        );
      } else if (this.isAuditoria() && !atendimentoEdicao) {
        return (
          <React.Fragment>
            <div className="flex-grow px-3 pb-4 m-auto justify-content-center">
              <label>Encaminhar</label>
              <CascadeSelectInput
                className="min-width"
                name={"status"}
                placeholder={"Selecione o status"}
                returnFullObject={true}
                labelKey={"nome"}
                valueKey={"id"}
                service={
                  this.validarStatusParaSelecao()
                  /*listaAtendimentos[0].numeroProtocolo != null ?
                                        statusAtendimentoService.findAllByAtendimentosViewComProtocolo
                                        :
                                        (this.props.numeroProtocolo > 0 ?
                                            statusAtendimentoService.findAllByAtendimentosViewComProtocolo
                                            :
                                            statusAtendimentoService.findAllByAtendimentos
                                        )*/
                }
                options={_.filter(
                  this.state.statusEdicaoEncaminhamento,
                  (status) => {
                    let atendimento = this.props.rowsCheckedValue[0];
                    if (
                      atendimento.status.situacao ===
                      objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE
                    ) {
                      return status.statusCancelamento;
                    }
                    return true;
                  }
                )}
                removeStatusRecusada={
                  (this.props.user.perfilAtual.perfil.tipoPerfil ===
                    objectsConstants.AUDITOR ||
                    this.props.user.perfilAtual.perfil.tipoPerfil ===
                      objectsConstants.AUDITOR_SEM_SETOR ||
                    this.props.user.perfilAtual.perfil.tipoPerfil ===
                      objectsConstants.PROTOCOLO) &&
                  this.props.user.cooperativa.id ===
                    objectsConstants.COOPMED.id &&
                  this.props.listaAtendimentos[0].status.situacao ===
                    objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA
                    ? true
                    : false
                }
                value={this.state.filter.status}
                onChange={this._handleChangeData}
                parent={this.props.rowsCheckedIdValue}
                loadingPlaceholder={"Selecione um atendimento"}
              />
            </div>
            <div className="flex-grow pr-4 m-auto justify-content-center">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!this.state.filter.status}
                onClick={async () => {
                  let filteredObjects = this.props.listaAtendimentos.filter(
                    (object, index) => {
                      return (
                        this.props.rowsCheckedIdValue.indexOf(object.id) !== -1
                      );
                    }
                  );
                  let convenios = _.uniqBy(
                    _.map(filteredObjects, function (n) {
                      return _.get(n, "convenio");
                    }),
                    "id"
                  );
                  if (this.validarPermisaoEditarStatusGuiaRecebida()) {
                    ConfirmAcationService.confirmMsg(
                      () => {},
                      "Existe(m) atendimento(s) já pendente(s),faturado(s), glosado(s) ou repassado(s)",
                      <span>
                        {" "}
                        Não é possivel encaminhar atendimento(s) pendente,
                        faturado, glosado ou repassado. Ou vc não tem permissão
                        para encaminhar o(s) atendimento(s) com o status
                        selecionado.
                      </span>,
                      null,
                      "OK",
                      null,
                      objectsConstants.TYPE_WARNING
                    );
                  } else {
                    if (this.state.filter.status.statusCancelamento) {
                      this.props.handleEncaminharAtendimento(
                        this.state.filter.status
                      );
                    } else {
                      if (
                        this.state.filter.status.nome ==
                        objectsConstants.STATUS_ENCAMINHAMENTO.NOME
                      ) {
                        if (
                          await this.validarPrazoGeracaoProtocolo(
                            this.state.filter.status
                          )
                        ) {
                          ConfirmAcationService.confirmMsgPrazoFaturaVencida(
                            () => {},
                            "Existem guias selecionadas com o prazo de faturamento vencido.",
                            <span>
                              {" "}
                              Guia vencida - prazo de faturamento superior a
                              negociação com convênio. Favor entrar em contato
                              com a cooperativa.
                              <br />
                              Para prosseguir com a geração do protocolo das
                              demais guias, favor desmarcar a(s) guia(s)
                              vencida(s) e encaminhar novamente.
                            </span>,
                            null,
                            "OK",
                            null,
                            objectsConstants.TYPE_WARNING
                          );
                        } else if (
                          this.validarQuantidadeAtendimentosGeracaoProtocolo()
                        ) {
                          this.props.error({
                            message:
                              "Não é possível gerar protocolo de mais de 100 atendimentos por vez.",
                          });
                        } else if (
                          !userService.getCurrentUser().cooperativa
                            .geraProtocoloSemLimiteConvenio &&
                          convenios.length > 1
                        ) {
                          this.setState({
                            convenios,
                            toogleModalCriarProtocolo: true,
                          });
                        } else {
                          ConfirmAcationService.confirmMsg(
                            () => {
                              this.props.handleEncaminharAtendimento(
                                this.state.filter.status
                              );
                              this.setState({ filter: {} });
                            },
                            "Encaminhar atendimentos",
                            <span>
                              Deseja realmente encaminhar{" "}
                              {`${
                                this.props.rowsCheckedIdValue.length === 1
                                  ? "o atendimento "
                                  : " os atendimentos"
                              } `}
                              para o status de{" "}
                              <span className="font-bold">
                                {this.state.filter.status.nome}
                              </span>
                              ?
                            </span>,
                            `${this.state.filter.status.nome} `,
                            "Não",
                            "Sim",
                            objectsConstants.TYPE_FORWARD
                          );
                        }
                      } else {
                        ConfirmAcationService.confirmMsg(
                          () => {
                            this.props.handleEncaminharAtendimento(
                              this.state.filter.status
                            );
                            this.setState({ filter: {} });
                          },
                          "Encaminhar atendimentos",
                          <span>
                            Deseja realmente encaminhar{" "}
                            {`${
                              this.props.rowsCheckedIdValue.length === 1
                                ? "o atendimento "
                                : " os atendimentos"
                            } `}
                            para o status de{" "}
                            <span className="font-bold">
                              {this.state.filter.status.nome}
                            </span>
                            ?
                          </span>,
                          `${this.state.filter.status.nome} `,
                          "Não",
                          "Sim",
                          objectsConstants.TYPE_FORWARD
                        );
                      }
                    }
                  }
                }}
              >
                {"Encaminhar"}
              </button>
            </div>
            <Modal
              isOpen={this.state.toogleModalCriarProtocolo}
              toggle={this.toogleModalCriarProtocolo}
              backdrop="static"
              modalClassName=""
              className=""
              centered={true}
            >
              <ModalBody>
                <div className="confirm-ui css-alert-sasc w-100">
                  <img src={iconCriarLote} alt="icon" />
                  <h2>Novo protocolo</h2>
                  <p>
                    <b></b>
                  </p>
                  <div className="row body-confirm">
                    <span>
                      {" "}
                      Existem atendimentos com convênios diferentes, para criar
                      o protocolo selecione o convenio desejado?
                    </span>
                  </div>
                  <SelectInput
                    className="min-width"
                    name={"setor"}
                    value={this.state.convenioProtocolo}
                    options={this.state.convenios}
                    onChange={(name, value) => {
                      this.setState({ convenioProtocolo: value });
                    }}
                    returnFullObject={true}
                    labelKey={"nome"}
                    valueKey={"id"}
                    placeholder="Selecione o Convenio"
                  />
                  <button
                    className="btn btn-secondary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      this.toogleModalCriarProtocolo();
                    }}
                  >
                    Não
                  </button>
                  <button
                    disabled={!this.state.convenioProtocolo}
                    dclassName="btn btn-primary white mt-3 mb-4 mx-2"
                    onClick={() => {
                      this.props.handleEncaminharAtendimento(
                        this.state.filter.status,
                        this.state.convenioProtocolo
                      );
                      this.toogleModalCriarProtocolo();
                    }}
                  >
                    Sim
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </React.Fragment>
        );
      }
    }
  };
  _handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  verificaStatusIgual = () => {
    var countBy = _.countBy(this.props.listaAtendimentos, "status.id");
    return Object.keys(countBy).length === 1 ? true : false;
  };

  bloqueiaEdicaoStatusGuiaRecebidaEGuiaEletronicaPerfilCooperadoConsultorio = () => {
    const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;
    if (
      tipoPerfil === objectsConstants.CONSULTORIO ||
      tipoPerfil === objectsConstants.COOPERADO
    ) {
      return true;
    }
    return false;
  };

  validaEdicaoGuiaRecusada = (status) => {
    const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;

    if (
      tipoPerfil === objectsConstants.ADMINISTRADOR ||
      tipoPerfil === objectsConstants.PROTOCOLO
    ) {
      if (
        status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO
      ) {
        //GUIA RECUSADA
        return status.nome === objectsConstants.STATUS_RECUSADO.NOME
          ? false
          : true;
      }
    }
    return true;
  };

  validaEmcaminhamentoGuiaPendente = (status) => {
    const user = this.props.user;
    const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;

    if (
      tipoPerfil === objectsConstants.ADMINISTRADOR ||
      tipoPerfil === objectsConstants.PROTOCOLO
    ) {
      if (status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE) {
        return status.nome === objectsConstants.PENDENTE ? false : true;
      }
    }
    return true;
  };

  isAuditoria = () => {
    for (let i = 0; i < this.props.listaAtendimentos.length; i++) {
      let atendimento = this.props.listaAtendimentos[i];
      if (
        !atendimento.status ||
        (atendimento.status.situacao !==
          objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA &&
          atendimento.status.situacao !==
            objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA &&
          atendimento.status.situacao !==
            objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA &&
          atendimento.status.situacao !==
            objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE &&
          atendimento.status.situacao !==
            objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA &&
          atendimento.status.situacao !==
            objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE &&
          this.validaEdicaoGuiaRecusada(atendimento.status) &&
          this.validaEmcaminhamentoGuiaPendente(atendimento.status)) ||
        ((atendimento.status.situacao ===
            objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA ||
          atendimento.status.situacao ===
           objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA) &&
          this.bloqueiaEdicaoStatusGuiaRecebidaEGuiaEletronicaPerfilCooperadoConsultorio())
      ) {
        return false;
      }
    }
    return true;
  };
  validarStatusParaSelecao = () => {
    const { tipoPerfil } = this.props.user.perfilAtual.perfil;
    const { situacao, nome } = this.props.listaAtendimentos[0].status;
    const perfisValidos = [
      objectsConstants.ADMINISTRADOR,
      objectsConstants.PROTOCOLO,
      objectsConstants.CONSULTORIO,
      objectsConstants.COOPERADO,
    ];
    const situacaoPendente = objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE;
    const nomesValidos = [
      objectsConstants.PENDENTE,
      objectsConstants.DUPLICIDADE,
      objectsConstants.INCONSISTENCIA_CARTEIRINHA,
    ];

    if (
      perfisValidos.includes(tipoPerfil) &&
      situacao === situacaoPendente &&
      nomesValidos.includes(nome)
    ) {
      return statusAtendimentoService.porAtendimentoPendenteGuiaRecusada;
    }

    if (this.props.listaAtendimentos[0].numeroProtocolo != null) {
      return statusAtendimentoService.findAllByAtendimentosViewComProtocolo;
    }
    if (this.props.numeroProtocolo > 0) {
      return statusAtendimentoService.findAllByAtendimentosViewComProtocolo;
    }
    return statusAtendimentoService.findAllByAtendimentos;
  };
  validarPermisaoEditarStatusGuiaRecebida = () => {
    const existeGuiaRecebida = this.props.listaAtendimentos.some(
      (atendimento) => atendimento.status.statusRecebimentoGuia
    );
    const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;
    if (
      existeGuiaRecebida &&
      (tipoPerfil === objectsConstants.CONSULTORIO ||
        tipoPerfil === objectsConstants.COOPERADO)
    ) {
      return true;
    }
    return false;
  };

  async validarPrazoGeracaoProtocolo(status) {
    this.props.loading(true);
    let prazoGerarProtocolo = [];
    let idsAtendimentos = [];

    let idsAtendimentosrows = _.map(this.props.rowsCheckedIdValue);

    if (idsAtendimentosrows.length > 0) {
      idsAtendimentos = _.map(this.props.rowsCheckedIdValue);
    } else {
      for (let i = 0; i < this.props.listaAtendimentos.length; i++) {
        idsAtendimentos[i] = this.props.listaAtendimentos[i].id;
      }
    }
    await atendimentoService
      .findAllAtendimentosPrazoGeracaoProtocolo(idsAtendimentos)
      .then((response) => {
        prazoGerarProtocolo.push(response.data);
      });
    this.props.loading(false);

    if (prazoGerarProtocolo.length > 0)
      return !prazoGerarProtocolo.some((ele) => ele === false);
    else return true;
  }

  handleChange = (name, value) => {
    let newState = _.cloneDeep(this.state);
    _.set(newState, name, value);
    this.setState(newState);
  };

  render() {
    const { listaAtendimentos, atendimentoEdicao } = this.props;
    const emEdicao = atendimentoEdicao > 0;

    listaAtendimentos.length === 1 &&
      this.setState({ numeroProtocolo: listaAtendimentos[0].numeroProtocolo });

    let msg = "";
    if (
      listaAtendimentos.length === 1 &&
      listaAtendimentos[0].atendimentoProcedimentos
    ) {
      listaAtendimentos[0].atendimentoProcedimentos.map((procedimento) => {
        if (procedimento.glosas) {
          msg = procedimento.glosas.map((glosa) => {
            return (
              dateHelper.format(glosa.dataGlosa, { pattern: "DD/MM/YYYY" }) +
              " - " +
              glosa.motivoGlosa +
              " - " +
              glosa.situacaoGlosa
            );
          });
        }
      });
    }

    return (
      //add classe "bg-cinza" abaixo quando edição/criação
      <div className="barra-topo pb-3 p-sm-0 topo-guia bg-branco d-flex flex-column flex-sm-row align-content-stretch flex-wrap">
        <div className="px-sm-5 py-4 bg-branco rounded-left  d-flex align-items-center justify-content-center">
          <img src={iconAtendimento} alt="Atendimento" className="iconMedio" />

          <div>
            <p className="mb-0">
              {" "}
              {`${
                this.props.rowsCheckedIdValue.length > 0
                  ? "Selecionados"
                  : "Exibindo"
              }`}{" "}
            </p>
            <h2
              className={`status-txt ${
                this.props.rowsCheckedIdValue.length && "verde-destaque"
              }`}
            >
              {listaAtendimentos.length > 1 &&
                (this.props.rowsCheckedIdValue.length
                  ? this.props.rowsCheckedIdValue.length
                  : listaAtendimentos.length) + " atendimentos "}
              {listaAtendimentos.length === 1 && (
                <span>{listaAtendimentos[0].numeroAtendimento}</span>
              )}
              {listaAtendimentos.length === 1 &&
                !listaAtendimentos[0].id &&
                "-"}
            </h2>
          </div>
        </div>
        <div className="flex-grow-1 px-3 m-auto ">
          <p className="mb-0 italic verde-destaque"> Status </p>
          <h2 className="status-txt">
            {listaAtendimentos.length > 1 &&
              !emEdicao &&
              this.verificaStatusIgual() &&
              listaAtendimentos[0].status &&
              listaAtendimentos[0].status.nome}
            {listaAtendimentos.length > 1 &&
              !emEdicao &&
              !this.verificaStatusIgual() &&
              "- "}
            {listaAtendimentos.length === 1 &&
              !emEdicao &&
              listaAtendimentos[0].status &&
              listaAtendimentos[0].status.nome}
            {listaAtendimentos.length === 1 &&
              !listaAtendimentos[0].id &&
              "Em Criação"}
            {emEdicao &&
              !(listaAtendimentos.length === 1 && !listaAtendimentos[0].id) &&
              "Em Edição"}
          </h2>
        </div>
        {listaAtendimentos.length === 1 &&
          listaAtendimentos[0].status &&
          listaAtendimentos[0].status.situacao ===
            objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO &&
          msg && (
            <div className="flex-grow-1 px-3 m-auto ">
              <p className="mb-0 italic text-danger"> Motivo de glosa </p>
              <i className="icon-danger" id={"help_"} />
              <ToolTipHelp
                className={"danger"}
                target={"help_"}
                message={msg}
              />
            </div>
          )}
        {listaAtendimentos.length === 1 &&
          listaAtendimentos[0].status &&
          listaAtendimentos[0].status.situacao ===
            objectsConstants.SITUACAO_ATENDIMENTO.REPASSADO && (
            <div className="flex-grow-1 px-3 m-auto ">
              {listaAtendimentos[0].atendimentoProcedimentos &&
                listaAtendimentos[0].atendimentoProcedimentos.map(
                  (procedimento) => {
                    return (
                      <React.Fragment>
                        <p className="mb-0 italic text-success">
                          {" "}
                          Data do Repasse({
                            procedimento.codigoProcedimento
                          }):{" "}
                        </p>
                        {dateHelper.format(procedimento.dataRepasse, {
                          pattern: "DD/MM/YYYY",
                        })}
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          )}
        <div className="d-flex borda-separacao align-items-center align-self-stretch flex-fill p-3 pb-4">
          <div className="w-100 mt-neg-25">
            <BuscaAtendimentoInput
              label={"Adicionar atendimento"}
              className="com-icone custom-padding-select min-width"
              name={"atendimento"}
              value={this.state.atendimento}
              options={""}
              onChange={this.handleChange}
              returnFullObject={true}
              onFetchData={atendimentoService.find}
              placeholder={"Digite o nº do atendimento"}
              onClickDropDown={() => {
                this.props.addAtendimentoView(this.state.atendimento);
                this.handleChange("atendimento", null);
              }}
              parent={"0"}
              labelKey={"numeroAtendimento"}
              valueKey={"id"}
            ></BuscaAtendimentoInput>
          </div>
        </div>
        {this.renderRightContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

const mapDispatch = ({ alert: { error }, load: { loading } }) => ({
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
});

export default connect(mapStateToProps, mapDispatch)(withRouter(GuiaTopo));
