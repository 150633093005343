//@flow
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { convenioService } from '../../services/convenio.service';
import TopoTitleComponente from '../home/TopoTitleComponente';
import ConveniosTopoView from './ConveniosTopoView';
import DadosConveniosForm from './componentes/DadosConveniosForm';
import CronogramaConveniosForm from './componentes/CronogramaConveniosForm';
import RegrasConveniosForm from './componentes/RegrasConveniosForm';
import ProcedimentosConveniosForm from "./componentes/ProcedimentosConveniosForm";
import IntegracaoConvenioForm from './componentes/IntegracaoConvenioForm';



class ConveniosView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true }
    };
  }

  componentDidMount() {
    this.loadConvenio();
  }

  loadConvenio = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      convenioService.doGet(this.props.match.params.id).then(
        response => {
          let convenio = response.data.data;
          this.setState({ entity: convenio }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  disableEnabledConvenio = () => {
    this.props.loading(true);
    convenioService.disableEnabledConvenio(this.state.entity.id).then((response) => {
      this.setState({ entity: response.data }, () => {
        this.props.loading(false);
      });
    })
  };


  render() {

    let _this = this;
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo pb-5">
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`Dados do convênio`}
              canBack={true}
              backUrl={'/convenios'}
            />
            <ConveniosTopoView
              values={entity}
              // getProfileName={_this.getProfileName}
              viewMode={true}
              disableEnabledConvenio={this.disableEnabledConvenio}
              qtdeAtendimento={1.636}
              qtdeProfissionais={47}
            ></ConveniosTopoView>
            <DadosConveniosForm convenio={entity} loadConvenio={this.loadConvenio}></DadosConveniosForm>
            <CronogramaConveniosForm convenio={entity} loadConvenio={this.loadConvenio}></CronogramaConveniosForm>
            <IntegracaoConvenioForm convenio={entity} loadConvenio={this.loadConvenio}></IntegracaoConvenioForm>
            <ProcedimentosConveniosForm convenio={entity} loadConvenio={this.loadConvenio}></ProcedimentosConveniosForm>
            <RegrasConveniosForm convenio={entity} loadConvenio={this.loadConvenio}></RegrasConveniosForm>

          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(ConveniosView));
