import React, { PureComponent } from "react";
import { objectsConstants } from "../../constants/objects.constants";
//import sombra from "../statusAtendimentosCustom/img/sombra.svg";

let startPage = 0;
let endPage = 0;

export default class StatusAtendimentosCustom_mobile extends PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      active: props.activeIndex ? props.activeIndex : null,
      colActive: "active",
      colInitial: "initial",
      lineActive: "line",
      scrollActive: "",
      scrollItem: "",
      currentItem: 0
    };
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.activeIndex === -1) {
      this.setState({
        active: null,
        colActive: "active",
        colInitial: "initial",
        lineActive: "line",
        scrollActive: "",
        scrollItem: "",
        currentItem: 0
      });
    }
  }

  toggleClass = (index, quantidadeMaiorZero, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (quantidadeMaiorZero) {
      const currentState = this.state.active;
      let colInitial = "initial";
      let active = null;
      let currentItem = 0;
      let lineActive = "line";
      let scrollActive = "";
      let scrollItem = this.state.scrollItem;
      if (currentState !== index) {
        active = index;
        colInitial = "inactive";
        if (index >= 0 && index < this.props.listaSubStatus.length) {
          scrollActive = `scroll${index}ON`
        } else {
          //lineActive = "line";
          colInitial = "initial";
        }
      } /*else {
        scrollItem = "scroll0" + index;
      }*/
      currentItem = index;

      this.setState(
        {
          colInitial,
          active,
          lineActive,
          scrollActive,
          scrollItem,
          currentItem
        },
        () => {
          if (this.props.onChangeStatus) {
            this.props.onChangeStatus(active, index);
          }
        }
      );
    }
  };

  componentDidMount() {
    document
      .getElementById("statusAtendimento")
      .addEventListener("touchstart", this.onDragStart);
    document
      .getElementById("statusAtendimento")
      .addEventListener("touchend", this.onDragEnd);
  }

  onDragStart = e => {
    if (e.pageX) {
      startPage = e.pageX;
    } else {
      startPage = e.changedTouches[0].clientX;
    }
  };
  onDragEnd = e => {
    let scrollItem = "";
    if (e.pageX) {
      endPage = e.pageX;
    } else {
      endPage = e.changedTouches[0].clientX;
    }

    if (this.state.scrollActive === "" && endPage !== startPage) {
      switch (this.state.scrollItem) {
        case "":
          if (endPage < startPage) {
            scrollItem = "scroll01";
          } else {
            scrollItem = "";
          }
          break;
        case "scroll01":
          if (endPage < startPage) {
            scrollItem = "scroll02";
          } else {
            scrollItem = "";
          }
          break;
        case "scroll02":
          if (endPage < startPage) {
            scrollItem = "scroll03";
          } else {
            scrollItem = "scroll01";
          }
          break;
        case "scroll03":
          if (endPage < startPage) {
            scrollItem = "scroll04";
          } else {
            scrollItem = "scroll02";
          }
          break;
        case "scroll04":
          if (endPage < startPage) {
            scrollItem = "scroll04";
          } else {
            scrollItem = "scroll03";
          }
          break;
        case "scroll05":
          if (endPage < startPage) {
            scrollItem = "scroll05";
          } else {
            scrollItem = "scroll04";
          }
          break;
        case "scroll06":
          if (endPage < startPage) {
            scrollItem = "scroll06";
          } else {
            scrollItem = "scroll05";
          }
          break;
        default:
          scrollItem = "";
      }
      this.setState({ scrollItem });
    }
  };

  scrollTo = index => {
    let scrollItem = "scroll0" + index;
    this.setState({ scrollItem, currentItem: index });
  };

  getClasseStatus(situacao) {
    let classe = "iconeAuditoria";
    switch (situacao) {
      case objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA:
        classe = "pendenciaResolvida";//Pendência resolvida
        break;
      case objectsConstants.SUB_STATUS.RECEBIDA:
        classe = "recebida";//Recebida
        break;
      case objectsConstants.SUB_STATUS.GUIA_ELETRONICA:
        classe = "guiaEletronica";
        break;
      case objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE:
        classe = "removidaLote";//Removidas do lote
        break;
      case objectsConstants.SUB_STATUS.FATURAMENTO:
        classe = "faturamento";//Faturamento
        break;
      case objectsConstants.SUB_STATUS.GLOSADO:
        classe = "glosado";//Glosado
        break;
      case objectsConstants.SUB_STATUS.REPASSADO:
        classe = "repassado";//Repassado
        break;
      case objectsConstants.SUB_STATUS.AUDITORIA:
        classe = "auditoria";//Auditoria
        break;
      case objectsConstants.SUB_STATUS.NAO_FATURADO:
        classe = "naoFaturado";//Não Faturado
        break;
      case objectsConstants.SUB_STATUS.PENDENTE:
        classe = "pendente";//Pendente
        break;
      default:
        classe = "auditoria";
    }
    return classe;
  }

  getIconeStatus(subStatus, off) {
    let icone = "iconeAuditoria";
    switch (subStatus.situacao) {
      case objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA:
        icone = off ? "iconePendenciaResolvidaOFF" : "iconePendenciaResolvida";//Pendência resolvida
        break;
      case objectsConstants.SUB_STATUS.GUIA_ELETRONICA:
        icone = off ? "iconeGuiaEltronicaOFF" : "iconeGuiaEletronica";//Guia Eletrônica
        break;
      case objectsConstants.SUB_STATUS.RECEBIDA:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Recebida
        break;
      case objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE:
        icone = off ? "iconeRemovidaLoteOFF" : "iconeRemovidaLote";//Removidas do lote
        break;
      case objectsConstants.SUB_STATUS.FATURAMENTO:
        icone = off ? "iconeFaturamentoOFF" : "iconeFaturamento";//Faturamento
        break;
      case objectsConstants.SUB_STATUS.GLOSADO:
        icone = off ? "iconeGlosadoOFF" : "iconeGlosado";//Glosado
        break;
      case objectsConstants.SUB_STATUS.REPASSADO:
        icone = off ? "iconeRepassadoOFF" : "iconeRepassado";//Repassado
        break;
      case objectsConstants.SUB_STATUS.AUDITORIA:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";//Auditoria
        break;
      case objectsConstants.SUB_STATUS.NAO_FATURADO:
        icone = off ? "iconeNaoFaturadoOFF" : "iconeNaoFaturado";//Não Faturado
        break;
      case objectsConstants.SUB_STATUS.PENDENTE:
        icone = off ? "iconePendenteOFF" : "iconePendente";//Pendente
        break;
      default:
        icone = off ? "iconeAuditoriaOFF" : "iconeAuditoria";
    }
    return icone;
  }

  bandeirasUsuario() {
    const user = this.props.user;
    let bandeiraPermitida = false;
    if (user.usuarioSubStatus.length > 0) {
      bandeiraPermitida = true;
    }
    return bandeiraPermitida;
  }

  render() {
    return (
      <React.Fragment>
        {/* MOBILE */}
        <div className="componenteStatus">
          <div
            id="statusAtendimento"
            className={`row horizonScroll ${this.state.active !== null ? this.state.scrollActive : ""
              } ${this.state.scrollItem}`}
            onDragStart={e => {
              this.onDragStart(e);
            }}
            onDragEnd={e => {
              this.onDragEnd(e);
            }}
          >
            {this.props.listaSubStatus &&
              this.props.listaSubStatus.map((subStatus, indexSub) => {
                return (
                  <React.Fragment key={indexSub}>
                    {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.ADMINISTRADOR
                      || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.PROTOCOLO
                      || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.SUDO)
                      && (
                        <div
                          className={`col ${this.state.active === indexSub 
                            ? this.state.colActive
                            : this.state.colInitial
                            } ${this.getClasseStatus(subStatus.situacao)}`}
                          onClick={(e) => {
                            this.toggleClass(indexSub, subStatus.count > 0, e);
                          }} >
                          <div
                            className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-2 mt-0 fade-in"
                                : "col-12 mt-4"
                                }`}>
                              <div
                                className={`${subStatus.count === 0
                                  ? this.getIconeStatus(subStatus, true)
                                  : this.getIconeStatus(subStatus, false)
                                  }`}
                              ></div>
                            </div>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-9 mb-0 fade-in"
                                : "col-12 mb-4"
                                }`}
                            >
                              <h2 style={{color: subStatus.nome === "Pendente" ? "yellow" : ''}}>
                                {subStatus.count}{" "}
                                {this.state.active === indexSub ? "Atendimentos" : ""}
                              </h2>
                              <p style={{color: subStatus.nome === "Pendente" ? "yellow" : ''}}>{subStatus.nome}</p>
                            </div>
                          </div>
                        </div>)}
                    {((this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.AUDITOR_SEM_SETOR
                      || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.COOPERADO) && !this.bandeirasUsuario()
                      && ((this.props.cooperativa.sigla.toUpperCase() == objectsConstants.SANTACOOP.name? true : 
                        subStatus.situacao !== objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA)
                        && subStatus.situacao !== objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE))
                      && (
                        <div
                          className={`col ${this.state.active === indexSub
                            ? this.state.colActive
                            : this.state.colInitial
                            } ${this.getClasseStatus(subStatus.situacao)}`}
                          onClick={(e) => {
                            this.toggleClass(indexSub, subStatus.count > 0, e);
                          }} >
                          <div
                            className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-2 mt-0 fade-in"
                                : "col-12 mt-4"
                                }`}>
                              <div
                                className={`${subStatus.count === 0
                                  ? this.getIconeStatus(subStatus, true)
                                  : this.getIconeStatus(subStatus, false)
                                  }`}
                              ></div>
                            </div>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-9 mb-0 fade-in"
                                : "col-12 mb-4"
                                }`}
                            >
                              <h2>
                                {subStatus.count}{" "}
                                {this.state.active === indexSub ? "Atendimentos" : ""}
                              </h2>
                              <p>{subStatus.nome}</p>
                            </div>
                          </div>
                        </div>)}
                    {((this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.AUDITOR_SEM_SETOR
                      || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.COOPERADO) && this.bandeirasUsuario())
                      && (
                        <div
                          className={`col ${this.state.active === indexSub
                            ? this.state.colActive
                            : this.state.colInitial
                            } ${this.getClasseStatus(subStatus.situacao)}`}
                          onClick={(e) => {
                            this.toggleClass(indexSub, subStatus.count > 0, e);
                          }} >
                          <div
                            className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-2 mt-0 fade-in"
                                : "col-12 mt-4"
                                }`}>
                              <div
                                className={`${subStatus.count === 0
                                  ? this.getIconeStatus(subStatus, true)
                                  : this.getIconeStatus(subStatus, false)
                                  }`}
                              ></div>
                            </div>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-9 mb-0 fade-in"
                                : "col-12 mb-4"
                                }`}
                            >
                              <h2>
                                {subStatus.count}{" "}
                                {this.state.active === indexSub ? "Atendimentos" : ""}
                              </h2>
                              <p>{subStatus.nome}</p>
                            </div>
                          </div>
                        </div>)}
                    {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO && !this.bandeirasUsuario()
                      && (subStatus.situacao !== objectsConstants.SUB_STATUS.PENDENCIA_RESOLVIDA
                        && subStatus.situacao !== objectsConstants.SUB_STATUS.REMOVIDAS_DO_LOTE
                        && subStatus.situacao !== objectsConstants.SUB_STATUS.GLOSADO
                        && subStatus.situacao !== objectsConstants.SUB_STATUS.REPASSADO))
                      && (
                        <div
                          className={`col ${this.state.active === indexSub
                            ? this.state.colActive
                            : this.state.colInitial
                            } ${this.getClasseStatus(subStatus.situacao)}`}
                          onClick={(e) => {
                            this.toggleClass(indexSub, subStatus.count > 0, e);
                          }} >
                          <div
                            className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-2 mt-0 fade-in"
                                : "col-12 mt-4"
                                }`}>
                              <div
                                className={`${subStatus.count === 0
                                  ? this.getIconeStatus(subStatus, true)
                                  : this.getIconeStatus(subStatus, false)
                                  }`}
                              ></div>
                            </div>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-9 mb-0 fade-in"
                                : "col-12 mb-4"
                                }`}
                            >
                              <h2>
                                {subStatus.count}{" "}
                                {this.state.active === indexSub ? "Atendimentos" : ""}
                              </h2>
                              <p>{subStatus.nome}</p>
                            </div>
                          </div>
                        </div>)}
                    {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO && this.bandeirasUsuario())
                      && (
                        <div
                          className={`col ${this.state.active === indexSub
                            ? this.state.colActive
                            : this.state.colInitial
                            } ${this.getClasseStatus(subStatus.situacao)}`}
                          onClick={(e) => {
                            this.toggleClass(indexSub, subStatus.count > 0, e);
                          }} >
                          <div
                            className={`row ${subStatus.count === 0 ? "colorOFF" : ""} conteudoComponente`}>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-2 mt-0 fade-in"
                                : "col-12 mt-4"
                                }`}>
                              <div
                                className={`${subStatus.count === 0
                                  ? this.getIconeStatus(subStatus, true)
                                  : this.getIconeStatus(subStatus, false)
                                  }`}
                              ></div>
                            </div>
                            <div
                              className={`${this.state.active === indexSub
                                ? "col-9 mb-0 fade-in"
                                : "col-12 mb-4"
                                }`}
                            >
                              <h2>
                                {subStatus.count}{" "}
                                {this.state.active === indexSub ? "Atendimentos" : ""}
                              </h2>
                              <p>{subStatus.nome}</p>
                            </div>
                          </div>
                        </div>)}
                  </React.Fragment>
                )
              })}
          </div>
          <div
            className={`row justify-content-center ${this.state.active !== null ? "disabled" : ""
              }`}
          >
            <div
              className={`${this.state.currentItem === 0 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(0);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 1 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(1);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 2 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(2);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 3 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(3);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 4 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(4);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 5 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(5);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 6 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(6);
              }}
            ></div>
            <div
              className={`${this.state.currentItem === 7 ? "ball_ON" : "ball_OFF"
                }`}
              onClick={() => {
                this.scrollTo(7);
              }}
            ></div>
            {(this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.ADMINISTRADOR
              || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.PROTOCOLO
              || this.props.user.perfilAtual.perfil.tipoPerfil === objectsConstants.SUDO) &&
              (<React.Fragment>
                <div
                  className={`${this.state.currentItem === 8 ? "ball_ON" : "ball_OFF"
                    }`}
                  onClick={() => {
                    this.scrollTo(8);
                  }}
                ></div>
                <div
                  className={`${this.state.currentItem === 9 ? "ball_ON" : "ball_OFF"
                    }`}
                  onClick={() => {
                    this.scrollTo(9);
                  }}
                ></div>
                <div
                  className={`${this.state.currentItem === 10 ? "ball_ON" : "ball_OFF"
                    }`}
                  onClick={() => {
                    this.scrollTo(10);
                  }}
                ></div></React.Fragment>)}
          </div></div>
      </React.Fragment>
    );
  }
}
