//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import type { Options } from './defaultService';

export const taskType = {
  ATENDIMENTO_PENDENCIA: { url: '/guias', field: '', withParam: false },
  ATENDIMENTO_DATA_ENVIO_FATURAMENTO: { url: '/guias', field: '', withParam: false },
  LOTE_COM_ERROR: { url: '/lotes', field: '', withParam: false },
  ATENDIMENTO_GLOSADO: { url: '/guias', field: '', withParam: false },
  INCONSISTENCIA_AUXILIARES: { url: '/guias', field: '', withParam: false },
  ATENDIMENTO_DUPLICIDADE: { url: '/guias', field: '', withParam: false },
  INTEGRACAO_MANUAL_OK: {url:'', field: '', withParam: false},
  INTEGRACAO_MANUAL_ERRO: {url:'', field: '', withParam: false}
};

export const notificacaoService = {
  getTaskType,
  getWsUrl,
  getWsTopic,
  findByFilter
};

function getTaskType(taskName: string) {
  return taskType[taskName];
}


function findByFilter(filter) {
  return defaultService.doPost(`${urlsConstants.NOTIFICACAO}findByFilter`, filter);
}

function getWsTopic(user) {
  let topics =[]
    topics.push( '/task/' +  user.perfilAtual.perfil.tipoPerfil);
 return topics;
}

function getWsUrl() {
  return urlsConstants.WS_WEBSOCKET;
}
