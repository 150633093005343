import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DadosAtendimentoGuiaForm from "./componentes/DadosAtendimentoGuiaForm";
import DadosAutorizacaoAtendimentoGuiaForm from "./componentes/DadosAutorizacaoAtendimentoGuiaForm";
import DadosBeneficiarioGuiaForm from "./componentes/DadosBeneficiarioGuiaForm";
import DadosContratadoGuiaForm from "./componentes/DadosContratadoGuiaForm";
import DadosExamesSolicitadosGuiaForm from "./componentes/DadosExamesSolicitadosGuiaForm";
import DadosExecucaoGuiaForm from "./componentes/DadosExecucaoGuiaForm";
import DadosProfissionalExecutanteGuiaForm from "./componentes/DadosProfissionalExecutanteGuiaForm";
import DadosSolicitacaoProcedimentoGuiaForm from "./componentes/DadosSolicitacaoProcedimentoGuiaForm";
import DadosSolicitanteGuiaForm from "./componentes/DadosSolicitanteGuiaForm";
import ValoresProcedimentosGuiaForm from "./componentes/ValoresProcedimentosGuiaForm";
import PassoaPassoItem from "./PassoaPassoItem";
//import DadosConvenioConsultaForm from "./componentes/guiaConsulta/DadosConvenioConsultaForm";

class GuiaSADTForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            criarGuiaPassoPasso,
            errors,
            setFieldValue,
            values,
            handleChange,
            setValues,
            viewMode,
            etapaAtual,
            changeGuia,
            ...otherProps
        } = this.props;

        return (
            <React.Fragment>
                <PassoaPassoItem
                    etapa={1}
                    atendimento={values}
                    etapaAtual={etapaAtual}
                    criarGuiaPassoPasso={criarGuiaPassoPasso}
                >
                    <DadosAutorizacaoAtendimentoGuiaForm
                        errors={errors}
                        user={this.props.user}
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        setValues={setValues}
                        atendimento={this.props.atendimento}
                    />

                    <DadosBeneficiarioGuiaForm
                        errors={errors}
                        setValues={setValues}
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        changeGuia={changeGuia}
                        {...otherProps}
                    />
                </PassoaPassoItem>
                <PassoaPassoItem
                    etapa={2}
                    atendimento={values}
                    etapaAtual={etapaAtual}
                    criarGuiaPassoPasso={criarGuiaPassoPasso}
                >
                    <DadosSolicitanteGuiaForm
                        errors={errors}
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        user={this.props.user}
                        listUfsConselho={this.props.listUfsConselho}
                        listConselho={this.props.listConselho}
                    />
                     <DadosProfissionalExecutanteGuiaForm
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        listUfsConselho={this.props.listUfsConselho}
                        listConselho={this.props.listConselho}
                        setValues={setValues}
                        {...otherProps}
                    />
                    <DadosSolicitacaoProcedimentoGuiaForm
                        setValues={setValues}
                        className={"section-form"}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        {...otherProps}
                    />

                    <DadosContratadoGuiaForm
                        errors={errors}
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        setValues={setValues}
                        handleChange={handleChange}
                        viewMode={viewMode}
                    />
                </PassoaPassoItem>

                <PassoaPassoItem
                    etapa={3}
                    atendimento={values}
                    etapaAtual={etapaAtual}
                    criarGuiaPassoPasso={criarGuiaPassoPasso}
                >
                    <DadosAtendimentoGuiaForm
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        {...otherProps}
                    />
                </PassoaPassoItem>

                <PassoaPassoItem
                    etapa={4}
                    atendimento={values}
                    etapaAtual={etapaAtual}
                    criarGuiaPassoPasso={criarGuiaPassoPasso}
                >
                    <DadosExecucaoGuiaForm
                        errors={errors}
                        setValues={setValues}
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        {...otherProps}
                    />
                   
                    <DadosExamesSolicitadosGuiaForm
                        className={"section-form"}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        viewMode={viewMode}
                        listUfsConselho={this.props.listUfsConselho}
                        listConselho={this.props.listConselho}
                        {...otherProps}
                    />
                </PassoaPassoItem>

                <PassoaPassoItem
                    etapa={5}
                    atendimento={values}
                    etapaAtual={etapaAtual}
                    criarGuiaPassoPasso={criarGuiaPassoPasso}
                >
                    <div className="w-100 h-20"></div>
                    <ValoresProcedimentosGuiaForm
                        errors={errors}
                        setFieldValue={setFieldValue}
                        values={values}
                        handleChange={handleChange}
                        viewMode={viewMode}
                    />
                </PassoaPassoItem>

            </React.Fragment>
        );
    }
}

const mapDispatch = ({
                         alert: {success, error, clear},
                         load: {loading},
                         pendencia: {togglePendencia, atendimentoAtual}
                     }) => ({
    success: msg => success(msg),
    loading: (load: boolean) => loading({load}),
    error: msg => error(msg),
    togglePendencia: () => togglePendencia(),
    atendimentoAtual: atendimento => atendimentoAtual(atendimento)
});

function mapStateToProps(state) {
    const {pendenciaOpen, idAtendimentoAtual} = state.pendencia;
    const {loggedIn, user, permissions} = state.authentication;
    const {alert} = state;
    const {filtroAtendimento} = state.filtro;

    return {
        pendenciaOpen,
        idAtendimentoAtual,
        loggedIn,
        alert,
        user,
        permissions,
        filtroAtendimento
    };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(GuiaSADTForm));
