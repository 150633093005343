import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import BtnLink from "../../../components/Buttons/BtnLink";
import { Can } from "@casl/react";
import { convenioService } from "../../../services/convenio.service";
import { Formik } from "formik";
import * as Yup from "yup";
import SelectInput from "../../../components/Inputs/SelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import { objectsConstants } from "../../../constants/objects.constants";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import FormInput from "../../../components/Inputs/FormInput";
import _ from "lodash";
import { formatterHelper } from "../../../helpers/formatter.helper";
import { messages } from "../../../helpers/message.helper";
import { FormGroup } from "../../../components/FormGroup";
import MenuHistorico from "../../guias/historico/MenuHistorico";
import MoneyInput from "../../../components/Inputs/MoneyInput";
import { procedimentoService } from "../../../services/procedimento.service";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import { enumService } from "../../../services/enum.service";
import FormTextCKEditor from "../../../components/Inputs/FormTextCKEditor";
import { campoAtendimentoService } from "../../../services/campoAtendimentoService";
import { viaAcessoService } from "../../../services/viaAcesso.service";
import { grauService } from "../../../services/grau.service";
import { especialidadesService } from "../../../services/especialidades.service";
import { hospitalService } from "../../../services/hospital.service";
import { estabelecimentoService } from "../../../services/estabelecimento.service";
import CreatableMulti from "../../../components/Inputs/CreatableMulti";

function isEmpty(obj) {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }
  return true;
}

const EditRegrasConvenio = Yup.object().shape({
  listRegrasProcedimentoTipoGuia: Yup.array().of(
    Yup.object().shape({
      tipoGuia: Yup.object().nullable(),
      procedimentos: Yup.array().nullable(),
      quantidade: Yup.number()
        .nullable()
        .min(1, "Digitar quantidade de procedimento maior que zero"),
    })
  ),

  listRegraMensagemTipoGuia: Yup.array().of(
    Yup.object().shape({
      tipoGuia: Yup.object().nullable(),
      campoAtendimentoTipoGuia: Yup.object().nullable(),
      mensagemAlerta: Yup.string().max(
        150,
        "A mensagem excede a quantidade limite de 150 caracteres"
      ),
    })
  ),

  instrucoesAtendimento: Yup.string().max(
    1700,
    "As instruções excederam a quantidade limite de caracteres"
  ),

  planoObrigatorio: Yup.string()
    .nullable(true)
    .test("planoObrigatorio", "Obrigatório", function () {
      const { parent, createError } = this;
      if (
        parent.planoObrigatorio === "true" &&
        (!parent.convenioPlanos || parent.convenioPlanos.length < 1)
      ) {
        return createError({
          path: "convenioPlanos",
          message: "Obrigatório",
        });
      }
      return true;
    }),

  listRegrasEspecialidadeProcedimentoTipoGuia: Yup.array().of(
    Yup.object().shape({
      tipoGuia: Yup.object().nullable(),
      procedimentos: Yup.array().when("tipoGuia", {
        is: (tipoGuia) => tipoGuia.id !== null && tipoGuia.id !== undefined,
        then: Yup.array().nullable().required("Campo Obrigatório"),
        otherwise: Yup.array().nullable(),
      }),
    })
  ),

  listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto: Yup.array().of(
    Yup.object().shape({
      procedimentos: Yup.array()
        .nullable()
        .test(
          'min-length',
          'Permitido no Mínimo 2 códigos de procedimento',
          function (value) {
            if (value && value.length === 1) {
              return false;
            }
            return true;
          }
        )
        .max(5, "Permitido no Máximo 5 códigos de procedimento")
    })
  ),
  listaRegrasProcedimentosBloqueadosGuiaHospitais: Yup.array().of(
    Yup.object().shape({
      tipoGuia: Yup.object()
        .nullable()
        .test(
          "todos-preenchidos",
          "Campo obrigatório",
          function (value) {
            const { procedimentos, hospitalLocal } = this.parent;
            if (isEmpty(value) && ((procedimentos && procedimentos.length > 0) || (hospitalLocal && hospitalLocal.length > 0))){
              return false;
            }
            return true;
          }
        ),
        procedimentos: Yup.array()
        .nullable()
        .test(
          "todos-preenchidos",
          "Campo obrigatório",
          function (value) {
            const { tipoGuia, hospitalLocal } = this.parent;        
            if ((!value || value.length == 0) && (!isEmpty(tipoGuia) ||  (hospitalLocal && hospitalLocal.length > 0))) {
              return false;
            }
            return true;
          }
        ),
        hospitalLocal: Yup.array()
        .nullable()
        .test(
          "todos-preenchidos",
          "Campo obrigatório",
          function (value) {
            const { tipoGuia, procedimentos } = this.parent;
            if ((!value || value.length == 0) && (!isEmpty(tipoGuia) || (procedimentos && procedimentos.length > 0))) {
              return false;
            }
            return true;
          }
        ),
    })
  ),
});

class RegrasConveniosForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      collapse: true,
      viewMode: true,
      listaHistoricos: [],
      habilitarReiniciarNumeroGuia: false,
    };
  }
  toggle = () => {
    this.setState((state) => ({ collapse: !state.collapse }));
  };
  toggleProcedimento = () => {
    this.setState((state) => ({ open: !state.open }));
  };
  changeViewMode = (e) => {
    e.stopPropagation();
    this.setState({ viewMode: !this.state.viewMode });
  };
  loadHistoryRegras = () => {
    this.props.loading(true);
    convenioService.historicoRegras(this.props.convenio.id).then(
      (result) => {
        this.setState({ listaHistoricos: result.data }, () => {
          this.props.loading(false);
        });
      },
      () => {
        this.props.loading(false);
      }
    );
  };
  setMenuHistorico = (status) => {
    this.setState({ openMenuHistorico: status }, () => {
      if (this.state.openMenuHistorico) {
        this.loadHistoryRegras();
        document.body.classList.add("bodyNoScroll");
        document.getElementById("bodyMenu").scrollIntoView();
      } else {
        document.body.classList.remove("bodyNoScroll");
      }
    });
  };
  toggleHistorico = (toggle) => {
    this.setMenuHistorico(!this.state.openMenuHistorico);
  };
  confirmCancel = (setValues) => {
    this.setState({ viewMode: true, collapse: true }, () => {
      setValues(this.props.convenio);
    });
  };
  editMode = (e) => {
    e.stopPropagation();
    this.setState({ viewMode: false, collapse: true });
  };
  cancelEditMode = (e, setValues) => {
    e.stopPropagation();
    ConfirmAcationService.confirmMsg(
      () => {
        this.confirmCancel(setValues);
      },
      `Cancelar Edição `,
      "Você confirma que deseja cancelar a edição das regras",
      null,
      "Não",
      "Sim",
      objectsConstants.TYPE_CANCEL
    );
  };

  verificarRegraTipoGuia = (tipoGuia) => {
    if (tipoGuia !== undefined && tipoGuia.id !== undefined) {
      return objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[tipoGuia.id].nome;
    } else {
      return "- -";
    }
  };

  render() {

    let initValues = _.cloneDeep(this.props.convenio);
    if (
      initValues.listaRegrasSenhaConvenio &&
      initValues.listaRegrasSenhaConvenio.length < 1
    ) {
      initValues.listaRegrasSenhaConvenio = [{}];
    }
    if (
      initValues.listRegraMensagemTipoGuia &&
      initValues.listRegraMensagemTipoGuia.length < 1
    ) {
      initValues.listRegraMensagemTipoGuia = [{}];
    }

    if (initValues.listRegrasProcedimentoTipoGuia) {
      for (const regra in initValues.listRegrasProcedimentoTipoGuia) {
        if (initValues.listRegrasProcedimentoTipoGuia[regra].quantidade === 0) {
          initValues.listRegrasProcedimentoTipoGuia[regra].quantidade = null;
        }
      }
    }
    if (initValues.listRegraMensagemTipoGuia) {
      for (const regra in initValues.listRegraMensagemTipoGuia) {
        if (initValues.listRegraMensagemTipoGuia[regra].quantidade === 0) {
          initValues.listRegraMensagemTipoGuia[regra].quantidade = null;
        }
      }
    }

    if (
      initValues.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto &&
      initValues.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto
        .length === 0
    ) {
      initValues.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto = [{}];
    }

    if (initValues.listCaracteresInicioCarteirinha) {
      for (const caracteres in initValues.listCaracteresInicioCarteirinha) {
        if (initValues.listCaracteresInicioCarteirinha[caracteres].quantidade === 0) {
          initValues.listCaracteresInicioCarteirinha[caracteres].quantidade = null;
        }
      }
    }

    if (initValues.listaRegrasProcedimentosBloqueadosGuiaHospitais &&
      initValues.listaRegrasProcedimentosBloqueadosGuiaHospitais.length === 0
    ) {
      initValues.listaRegrasProcedimentosBloqueadosGuiaHospitais = [
        {tipoGuia:{}, procedimentos:[], hospitalLocal:[]}
      ]
      
    }
    return (
      <React.Fragment>
        <Formik
          validationSchema={EditRegrasConvenio}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={initValues}
          onSubmit={(values, { setValues }) => {
            this.props.loading(true);
            let convenio = _.cloneDeep(values);
            convenioService.doSave(convenio).then(
              (response) => {
                this.props.success({
                  message: `Regras salvas com sucesso!`,
                });
                this.props.loading(false);
                this.setState({ viewMode: true, collapse: true }, () => {
                  this.props.loadConvenio();
                });
              },
              (errors) => {
                this.props.error({
                  message:
                    "Não foi possível salvar Cronograma para faturamento.",
                });
                this.props.loading(false);
              }
            );
          }}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateForm,
            setValues,
          }) => (
            values.numeroGuiaObrigatoria != undefined
              ? this.setState({
                habilitarReiniciarNumeroGuia: values.numeroGuiaObrigatoria,
              })
              : "",
            (
              <form onSubmit={handleSubmit} noValidate>
                <div
                  className={`mt-4 accordion ${this.state.collapse ? "open" : ""
                    }`}
                >
                  <div className="card content-form cardGuia cardCrud">
                    <div
                      className="card-header d-flex align-items-center flex-column flex-sm-row b-bottom"
                      id="headingOne"
                      onClick={this.toggle}
                    >
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div className="p-1 status-guia">Regras</div>
                      </div>
                      <div className="buttons-right ml-auto">
                        {this.state.viewMode && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.EDITAR_CONVENIO}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-historico"}
                              title={"Histórico"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.toggleHistorico();
                              }}
                              disabled={false}
                            />

                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Editar"}
                              onSubmit={this.editMode}
                              disabled={false}
                            />
                          </Can>
                        )}
                        {!this.state.viewMode && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_ATENDIMENTOS_CRIAR}
                            ability={this.props.permissions}
                          >
                            <button
                              type={"button"}
                              className={"btn btn-secondary btn-pequeno mr-3"}
                              onClick={(e) => this.cancelEditMode(e, setValues)}
                            >
                              Cancelar
                            </button>
                            <button
                              type={"button"}
                              onClick={(e) => {
                                e.stopPropagation();
                                validateForm(values).then((errors) => {
                                  if (_.isEmpty(errors)) {
                                    handleSubmit(values, { setValues });
                                  } else {
                                    this.props.error({
                                      message:
                                        messages.ERRO_CRIAR_REGRAS_CONVENIO,
                                    });
                                  }
                                });
                              }}
                              className={"btn btn-primary btn-pequeno mr-2"}
                              icone={"icon-btn-check-black"}
                            >
                              Salvar
                            </button>
                          </Can>
                        )}
                      </div>
                      <span
                        className={`accordion ${this.state.collapse ? "icon-traco" : "icon-seta-baixo"
                          }`}
                      ></span>
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                      <div
                        id="guiaUm"
                        className="form-inside collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#guiaUm"
                      >
                        <React.Fragment>
                          <div class="row section-form">
                            <h6 className="w-100">
                              Regras Gerais por tipo de Guia
                            </h6>
                            {values.listaRegrasSenhaConvenio &&
                              values.listaRegrasSenhaConvenio.map(
                                (regra, index) => {
                                  return (
                                    <React.Fragment>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label="Guia"
                                          component={SelectInput}
                                          multi={false}
                                          isMulti={false}
                                          options={
                                            objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                          }
                                          name={`listaRegrasSenhaConvenio[${index}].tipoGuia`}
                                          searchable={true}
                                          returnFullObject={true}
                                          defaultValue={this.verificarRegraTipoGuia(
                                            regra.tipoGuia
                                          )}
                                          value={regra.tipoGuia}
                                          labelKey={"nome"}
                                          valueKey={"id"}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value);
                                          }}
                                          apendRoot={true}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label="Senha Obrigatória?"
                                          component={SelectInput}
                                          options={objectsConstants.SIM_NAO}
                                          name={`listaRegrasSenhaConvenio[${index}].senhaObrigatoria`}
                                          searchable={true}
                                          returnFullObject={true}
                                          defaultValue={
                                            regra.senhaObrigatoria &&
                                              regra.senhaObrigatoria.value ==
                                              false
                                              ? "Não"
                                              : formatterHelper.getLabelEnum(
                                                regra.senhaObrigatoria &&
                                                  regra.senhaObrigatoria.value
                                                  ? regra.senhaObrigatoria
                                                    .value
                                                  : regra.senhaObrigatoria,
                                                objectsConstants.SIM_NAO
                                              )
                                          }
                                          value={
                                            _.isBoolean(regra.senhaObrigatoria)
                                              ? formatterHelper.getEnum(
                                                regra.senhaObrigatoria,
                                                objectsConstants.SIM_NAO
                                              )
                                              : regra.senhaObrigatoria
                                          }
                                          labelKey={"label"}
                                          valueKey={"value"}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value.value);
                                          }}
                                          apendRoot={true}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label="Caráter de internação com senha obrigatória"
                                          component={FormSelectInput}
                                          service={
                                            enumService.findCaraterAtendimento
                                          }
                                          name={`listaRegrasSenhaConvenio[${index}].caraterAtendimentoSenhaObrigatoria`}
                                          multi={true}
                                          isMulti={true}
                                          defaultValue={
                                            regra.caraterAtendimentoSenhaObrigatoria
                                              ? regra.caraterAtendimentoSenhaObrigatoria.map(
                                                (carater, index) => {
                                                  let value =
                                                    carater.description;
                                                  if (index > 0) {
                                                    value = ", " + value;
                                                  }
                                                  return value;
                                                }
                                              )
                                              : ""
                                          }
                                          returnFullObject={true}
                                          searchable={true}
                                          labelKey={"description"}
                                          required={true}
                                          erroMensagem={_.get(
                                            errors,
                                            `caraterAtendimento`
                                          )}
                                          valueKey={"name"}
                                          value={
                                            regra.caraterAtendimentoSenhaObrigatoria
                                          }
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value);
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          component={SelectInput}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          id={`tipoSenha`}
                                          label={"Senha"}
                                          options={
                                            objectsConstants.TIPOS_VALIDACAO
                                          }
                                          name={`listaRegrasSenhaConvenio[${index}].tipoSenha`}
                                          placeholder=""
                                          defaultValue={_.get(
                                            regra.tipoSenha,
                                            "description"
                                          )}
                                          className={""}
                                          viewMode={this.state.viewMode}
                                          returnFullObject={true}
                                          searchable={true}
                                          labelKey={"description"}
                                          valueKey={"name"}
                                          value={regra.tipoSenha}
                                          apendRoot={true}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          component={FormInput}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          id={`quantDigitosSenha`}
                                          label={
                                            "Quantidade de digitos de senha"
                                          }
                                          name={`listaRegrasSenhaConvenio[${index}].quantDigitosSenha`}
                                          placeholder=""
                                          className={""}
                                          viewMode={this.state.viewMode}
                                          type={"number"}
                                          value={regra.quantDigitosSenha}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          component={FormInput}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          id={`caracteresInicioSenha`}
                                          label={
                                            "Caracteres de início da senha"
                                          }
                                          name={`listaRegrasSenhaConvenio[${index}].caracteresInicioSenha`}
                                          placeholder=""
                                          className={""}
                                          viewMode={this.state.viewMode}
                                          value={regra.caracteresInicioSenha}
                                        />
                                      </FormGroup>
                                      <FormGroup
                                        className="w-20"
                                        onClick={() =>
                                          this.toggleProcedimento()
                                        }
                                      >
                                        <div className={`direita`}>
                                          <span
                                            className={`accordion2 ${this.state.open
                                              ? "icon-traco"
                                              : "icon-seta-baixo"
                                              }`}
                                          ></span>
                                        </div>
                                        <label>
                                          Procedimentos com senha obrigatória
                                          <Collapse isOpen={this.state.open}>
                                            <InputViewEdit
                                              component={SelectInput}
                                              multi={true}
                                              isMulti={true}
                                              onChange={(name, date) => {
                                                setFieldValue(name, date);
                                              }}
                                              viewMode={this.state.viewMode}
                                              required={false}
                                              name={`listaRegrasSenhaConvenio[${index}].procedimentosSenhaObrigatoria`}
                                              placeholder="Digite o código"
                                              type={"text"}
                                              value={
                                                regra.procedimentosSenhaObrigatoria
                                              }
                                              clearable={true}
                                              defaultValue={
                                                regra.procedimentosSenhaObrigatoria
                                                  ? regra.procedimentosSenhaObrigatoria.map(
                                                    (procedimento, index) => {
                                                      let value =
                                                        procedimento.codigo;
                                                      if (index > 0) {
                                                        value = ", " + value;
                                                      }
                                                      return value;
                                                    }
                                                  )
                                                  : ""
                                              }
                                              id={`listaRegrasSenhaConvenio[${index}].procedimentosSenhaObrigatoria`}
                                              labelKey={"codigo"}
                                              valueKey={"codigo"}
                                              returnFullObject={true}
                                              onFetchData={
                                                procedimentoService.find
                                              }
                                            />
                                          </Collapse>
                                        </label>
                                      </FormGroup>
                                      <span className="quebra" />
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label={"Planos com senha obrigatória"}
                                          component={SelectInput}
                                          multi={true}
                                          isMulti={true}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value);
                                          }}
                                          viewMode={this.state.viewMode}
                                          required={false}
                                          name={`listaRegrasSenhaConvenio[${index}].planosSenhaObrigatoria`}
                                          placeholder="Digite o plano"
                                          type={"text"}
                                          value={regra.planosSenhaObrigatoria}
                                          clearable={true}
                                          defaultValue={
                                            regra.planosSenhaObrigatoria
                                              ? regra.planosSenhaObrigatoria.map(
                                                (plano, index) => {
                                                  let value = plano.plano;
                                                  if (index > 0) {
                                                    value = ", " + value;
                                                  }
                                                  return value;
                                                }
                                              )
                                              : ""
                                          }
                                          id={`listaRegrasSenhaConvenio${index}planosSenhaObrigatoria`}
                                          erroMensagem={_.get(
                                            errors,
                                            `listaRegrasSenhaConvenio[${index}].planosSenhaObrigatoria`
                                          )}
                                          labelKey={"label"}
                                          valueKey={"plano"}
                                          returnFullObject={true}
                                          onFetchData={convenioService.findPlano}
                                          parent={{
                                            id: this.props.convenio.id,
                                          }}
                                        />
                                      </FormGroup>
                                      <span className="quebra" />

                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label={
                                            "Procedimento não atendido pela guia"
                                          }
                                          component={SelectInput}
                                          multi={true}
                                          isMulti={true}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          viewMode={this.state.viewMode}
                                          required={false}
                                          name={`listaRegrasSenhaConvenio[${index}].procedimentos`}
                                          placeholder="Digite o código"
                                          type={"text"}
                                          value={regra.procedimentos}
                                          clearable={true}
                                          defaultValue={
                                            regra.procedimentos
                                              ? regra.procedimentos.map(
                                                (procedimento, index) => {
                                                  let value =
                                                    procedimento.codigo;
                                                  if (index > 0) {
                                                    value = ", " + value;
                                                  }
                                                  return value;
                                                }
                                              )
                                              : ""
                                          }
                                          id={`listaRegrasSenhaConvenio${index}procedimentos`}
                                          erroMensagem={_.get(
                                            errors,
                                            `listaRegrasSenhaConvenio[${index}].procedimentos`
                                          )}
                                          labelKey={"codigo"}
                                          valueKey={"codigo"}
                                          returnFullObject={true}
                                          onFetchData={procedimentoService.find}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label={
                                            "Procedimentos atendidos pela guia"
                                          }
                                          component={SelectInput}
                                          multi={true}
                                          isMulti={true}
                                          onChange={(name, date) => {
                                            setFieldValue(name, date);
                                          }}
                                          viewMode={this.state.viewMode}
                                          required={false}
                                          name={`listaRegrasSenhaConvenio[${index}].procedimentosPermitidosTipoGuia`}
                                          placeholder="Digite o código"
                                          type={"text"}
                                          value={
                                            regra.procedimentosPermitidosTipoGuia
                                          }
                                          clearable={true}
                                          defaultValue={
                                            regra.procedimentosPermitidosTipoGuia
                                              ? regra.procedimentosPermitidosTipoGuia.map(
                                                (procedimento, index) => {
                                                  let value =
                                                    procedimento.codigo;
                                                  if (index > 0) {
                                                    value = ", " + value;
                                                  }
                                                  return value;
                                                }
                                              )
                                              : ""
                                          }
                                          id={`listaRegrasSenhaConvenio${index}procedimentosPermitidosTipoGuia`}
                                          erroMensagem={_.get(
                                            errors,
                                            `listaRegrasSenhaConvenio[${index}].procedimentosPermitidosTipoGuia`
                                          )}
                                          labelKey={"codigo"}
                                          valueKey={"codigo"}
                                          returnFullObject={true}
                                          onFetchData={procedimentoService.find}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label={
                                            "Complementos não atendidos pela guia"
                                          }
                                          component={FormSelectInput}
                                          parent={values}
                                          service={
                                            convenioService.findComplementoConvenio
                                          }
                                          className={"destaque"}
                                          id={`listaRegrasSenhaConvenio${index}complementos`}
                                          name={`listaRegrasSenhaConvenio[${index}].complementos`}
                                          returnFullObject={true}
                                          multi={true}
                                          isMulti={true}
                                          erroMensagem={_.get(
                                            errors,
                                            `listaRegrasSenhaConvenio${index}.complementos`
                                          )}
                                          searchable={true}
                                          labelKey={"descricao"}
                                          valueKey={"id"}
                                          defaultValue={
                                            regra.complementos
                                              ? regra.complementos.map(
                                                (complemento, index) => {
                                                  let value =
                                                    complemento.descricao;
                                                  if (index > 0) {
                                                    value = ", " + value;
                                                  }
                                                  return value;
                                                }
                                              )
                                              : ""
                                          }
                                          value={regra.complementos}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value);
                                          }}
                                        />
                                      </FormGroup>

                                      <FormGroup className="w-20">
                                        <InputViewEdit
                                          label={
                                            "Acomodação não atendida pela guia"
                                          }
                                          component={FormSelectInput}
                                          parent={values}
                                          service={enumService.findAcomodacoes}
                                          className={"destaque"}
                                          id={`listaRegrasSenhaConvenio${index}acomodacoes`}
                                          name={`listaRegrasSenhaConvenio[${index}].acomodacoes`}
                                          returnFullObject={true}
                                          multi={true}
                                          isMulti={true}
                                          erroMensagem={_.get(
                                            errors,
                                            `listaRegrasSenhaConvenio${index}.acomodacoes`
                                          )}
                                          searchable={true}
                                          labelKey={"description"}
                                          valueKey={"name"}
                                          defaultValue={
                                            regra.acomodacoes
                                              ? regra.acomodacoes.map(
                                                (acomodacao, index) => {
                                                  let value =
                                                    acomodacao.description;
                                                  if (index > 0) {
                                                    value = ", " + value;
                                                  }
                                                  return value;
                                                }
                                              )
                                              : "--"
                                          }
                                          value={regra.acomodacoes}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value);
                                          }}
                                        />
                                      </FormGroup>

                                      <FormGroup className="w-18">
                                        <InputViewEdit
                                          label="Desbloquear criação de lote"
                                          component={SelectInput}
                                          options={objectsConstants.SIM_NAO}
                                          name={`listaRegrasSenhaConvenio[${index}].criarLote`}
                                          searchable={true}
                                          returnFullObject={true}
                                          defaultValue={
                                            regra.criarLote &&
                                              regra.criarLote.value == false
                                              ? "Não"
                                              : formatterHelper.getLabelEnum(
                                                regra.criarLote &&
                                                  regra.criarLote.value
                                                  ? regra.criarLote.value
                                                  : regra.criarLote,
                                                objectsConstants.SIM_NAO
                                              )
                                          }
                                          value={
                                            _.isBoolean(regra.criarLote)
                                              ? formatterHelper.getEnum(
                                                regra.criarLote,
                                                objectsConstants.SIM_NAO
                                              )
                                              : regra.criarLote
                                          }
                                          labelKey={"label"}
                                          valueKey={"value"}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value.value);
                                          }}
                                          apendRoot={true}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-18">
                                        <InputViewEdit
                                          label="CPF Obrigatório?"
                                          component={SelectInput}
                                          options={objectsConstants.SIM_NAO}
                                          name={`listaRegrasSenhaConvenio[${index}].cpfObrigatorio`}
                                          searchable={true}
                                          returnFullObject={true}
                                          defaultValue={
                                            regra.cpfObrigatorio &&
                                              regra.cpfObrigatorio.value == false
                                              ? "Não"
                                              : formatterHelper.getLabelEnum(
                                                regra.cpfObrigatorio &&
                                                  regra.cpfObrigatorio.value
                                                  ? regra.cpfObrigatorio.value
                                                  : regra.cpfObrigatorio,
                                                objectsConstants.SIM_NAO
                                              )
                                          }
                                          value={
                                            _.isBoolean(regra.cpfObrigatorio)
                                              ? formatterHelper.getEnum(
                                                regra.cpfObrigatorio,
                                                objectsConstants.SIM_NAO
                                              )
                                              : regra.cpfObrigatorio
                                          }
                                          labelKey={"label"}
                                          valueKey={"value"}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value.value);
                                          }}
                                          apendRoot={true}
                                        />
                                      </FormGroup>
                                      <FormGroup className="w-18">
                                        <InputViewEdit
                                          label="Data de nascimento Obrigatória?"
                                          component={SelectInput}
                                          options={objectsConstants.SIM_NAO}
                                          name={`listaRegrasSenhaConvenio[${index}].dataNascimentoObrigatorio`}
                                          searchable={true}
                                          returnFullObject={true}
                                          defaultValue={
                                            regra.dataNascimentoObrigatorio &&
                                              regra.dataNascimentoObrigatorio
                                                .value == false
                                              ? "Não"
                                              : formatterHelper.getLabelEnum(
                                                regra.dataNascimentoObrigatorio &&
                                                  regra
                                                    .dataNascimentoObrigatorio
                                                    .value
                                                  ? regra
                                                    .dataNascimentoObrigatorio
                                                    .value
                                                  : regra.dataNascimentoObrigatorio,
                                                objectsConstants.SIM_NAO
                                              )
                                          }
                                          value={
                                            _.isBoolean(
                                              regra.dataNascimentoObrigatorio
                                            )
                                              ? formatterHelper.getEnum(
                                                regra.dataNascimentoObrigatorio,
                                                objectsConstants.SIM_NAO
                                              )
                                              : regra.dataNascimentoObrigatorio
                                          }
                                          labelKey={"label"}
                                          valueKey={"value"}
                                          viewMode={this.state.viewMode}
                                          onChange={(name, value) => {
                                            setFieldValue(name, value.value);
                                          }}
                                          apendRoot={true}
                                        />
                                      </FormGroup>

                                      <React.Fragment>
                                        <FormGroup className="central fixo mobile-100">
                                          {!this.state.viewMode && (
                                            <React.Fragment>
                                              <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                {values.listaRegrasSenhaConvenio
                                                  .length > 1 && (
                                                    <button
                                                      className="btn-menos"
                                                      type="button"
                                                      onClick={() => {
                                                        let listaRegrasSenhaConvenio =
                                                          _.cloneDeep(
                                                            values.listaRegrasSenhaConvenio
                                                          );
                                                        listaRegrasSenhaConvenio.splice(
                                                          index,
                                                          1
                                                        );
                                                        setFieldValue(
                                                          "listaRegrasSenhaConvenio",
                                                          listaRegrasSenhaConvenio
                                                        );
                                                      }}
                                                    ></button>
                                                  )}
                                                <button
                                                  className="btn-mais"
                                                  type="button"
                                                  onClick={() => {
                                                    let listaRegrasSenhaConvenio =
                                                      _.cloneDeep(
                                                        values.listaRegrasSenhaConvenio
                                                      );
                                                    listaRegrasSenhaConvenio.push(
                                                      {}
                                                    );
                                                    setFieldValue(
                                                      "listaRegrasSenhaConvenio",
                                                      listaRegrasSenhaConvenio
                                                    );
                                                  }}
                                                ></button>
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </FormGroup>
                                      </React.Fragment>
                                      <div className="quebra"></div>
                                      <br></br>
                                      {/*</div>*/}
                                    </React.Fragment>
                                  );
                                }
                              )}

                                <h6 className="w-100">Regras de procedimentos bloqueados por tipo de guia e hospital</h6>
                                {values.listaRegrasProcedimentosBloqueadosGuiaHospitais &&
                                  values.listaRegrasProcedimentosBloqueadosGuiaHospitais.map((regra, index) => {
                                    return (
                                      <React.Fragment>
                                        <div className="row w-100 mx-0">
                                        <FormGroup className="w-15">
                                          <InputViewEdit
                                            label="Guia"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            options={
                                              objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                            }
                                            name={`listaRegrasProcedimentosBloqueadosGuiaHospitais[${index}].tipoGuia`}
                                            id={`listaRegrasProcedimentosBloqueadosGuiaHospitais-${index}-tipoGuia`}
                                            value={regra.tipoGuia}
                                            searchable={true}
                                            returnFullObject={true}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);                              
                                            }}
                                            placeholder={"Guia"}
                                            defaultValue={
                                              this.state.viewMode &&
                                                regra.tipoGuia &&
                                                regra.tipoGuia.id
                                                ? objectsConstants
                                                  .TIPOS_GUIA_REGRA_CONVENIO[
                                                  regra.tipoGuia.id
                                                ].nome
                                                : true
                                            }
                                            apendRoot={true}
                                            erroMensagem={_.get(errors, `listaRegrasProcedimentosBloqueadosGuiaHospitais.${index}.tipoGuia`, '')}
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={
                                              "Procedimentos não atendidos pelo hospital"
                                            }
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listaRegrasProcedimentosBloqueadosGuiaHospitais[${index}]procedimentos`}
                                            id={`listaRegrasProcedimentosBloqueadosGuiaHospitais-${index}-procedimentos`}
                                            placeholder="Digite o código"
                                            type={"text"}
                                            value={regra.procedimentos}
                                            clearable={true}
                                            erroMensagem={_.get(errors, `listaRegrasProcedimentosBloqueadosGuiaHospitais.${index}.procedimentos`, '')}
                                            labelKey={"codigo"}
                                            valueKey={"codigo"}
                                            returnFullObject={true}
                                            onFetchData={procedimentoService.find}
                                            defaultValue={
                                              regra.procedimentos
                                                ? regra.procedimentos.map(
                                                  (procedimento, index) => {
                                                    let value =
                                                      procedimento.codigo;
                                                    if (index > 0) {
                                                      value = ", " + value;
                                                    }
                                                    return value;
                                                  }
                                                )
                                                : ""
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-30">
                                          <InputViewEdit
                                            label={
                                              "Hospitais"
                                            }
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listaRegrasProcedimentosBloqueadosGuiaHospitais[${index}].hospitalLocal`}
                                            id={`listaRegrasProcedimentosBloqueadosGuiaHospitais-${index}-hospitalLocal`}
                                            placeholder="Digite o nome do hospital"
                                            type={"text"}
                                            value={regra.hospitalLocal}
                                            clearable={true}
                                            defaultValue={
                                              regra.hospitalLocal
                                                ? regra.hospitalLocal.map(
                                                  (hospital, index) => {
                                                    let nome = hospital.nome;
                                                    if (index > 0) {
                                                      nome = ", " + nome;
                                                    }
                                                    return nome;
                                                  }
                                                )
                                                : ""
                                            }

                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            returnFullObject={true}
                                            onFetchData={hospitalService.find}
                                            erroMensagem={_.get(errors, `listaRegrasProcedimentosBloqueadosGuiaHospitais.${index}.hospitalLocal`, '')}
                                          />
                                        </FormGroup>
                                        <React.Fragment>
                                          <FormGroup className="central fixo mobile-100">
                                            {!this.state.viewMode && (
                                              <React.Fragment>
                                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                  {values.listaRegrasProcedimentosBloqueadosGuiaHospitais
                                                    .length > 1 && (
                                                      <button
                                                        className="btn-menos"
                                                        type="button"
                                                        onClick={() => {
                                                          let listaRegrasProcedimentosBloqueadosGuiaHospitais =
                                                            _.cloneDeep(
                                                              values.listaRegrasProcedimentosBloqueadosGuiaHospitais
                                                            );
                                                          listaRegrasProcedimentosBloqueadosGuiaHospitais.splice(
                                                            index,
                                                            1
                                                          );
                                                          setFieldValue(
                                                            "listaRegrasProcedimentosBloqueadosGuiaHospitais",
                                                            listaRegrasProcedimentosBloqueadosGuiaHospitais
                                                          );
                                                        }}
                                                      ></button>
                                                    )}
                                                  <button
                                                    className="btn-mais"
                                                    type="button"
                                                    onClick={() => {
                                                      let listaRegrasProcedimentosBloqueadosGuiaHospitais =
                                                        _.cloneDeep(
                                                          values.listaRegrasProcedimentosBloqueadosGuiaHospitais
                                                        );
                                                      listaRegrasProcedimentosBloqueadosGuiaHospitais.push(
                                                        {tipoGuia:{}, procedimentos:[], hospitalLocal:[]}
                                                      );
                                                      setFieldValue(
                                                        "listaRegrasProcedimentosBloqueadosGuiaHospitais",
                                                        listaRegrasProcedimentosBloqueadosGuiaHospitais
                                                      );
                                                    }}
                                                  ></button>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </FormGroup>
                                        </React.Fragment>
                                        </div>
                                      </React.Fragment>
                                      )
                                  })}

                            <h6 class="w-100">Planos do convênio</h6>
                            <React.Fragment>
                              <div className="row w-100 mx-0">
                                <FormGroup className="w-10">
                                  <InputViewEdit
                                    label="Plano Obrigatório?"
                                    component={SelectInput}
                                    options={objectsConstants.SIM_NAO}
                                    name={"planoObrigatorio"}
                                    searchable={true}
                                    returnFullObject={true}
                                    defaultValue={
                                      values.planoObrigatorio &&
                                        values.planoObrigatorio.value == false
                                        ? "Não"
                                        : formatterHelper.getLabelEnum(
                                          values.planoObrigatorio &&
                                            values.planoObrigatorio.value
                                            ? values.planoObrigatorio.value
                                            : values.planoObrigatorio,
                                          objectsConstants.SIM_NAO
                                        )
                                    }
                                    value={
                                      _.isBoolean(values.planoObrigatorio)
                                        ? formatterHelper.getEnum(
                                          values.planoObrigatorio,
                                          objectsConstants.SIM_NAO
                                        )
                                        : values.planoObrigatorio
                                    }
                                    labelKey={"label"}
                                    valueKey={"value"}
                                    viewMode={this.state.viewMode}
                                    onChange={(name, value) => {
                                      setFieldValue(name, value.value);
                                    }}
                                    apendRoot={true}
                                  />
                                </FormGroup>
                                <FormGroup className="w-20">
                                  <InputViewEdit
                                    component={CreatableMulti}
                                    onChange={setFieldValue}
                                    id={`plano`}
                                    erroMensagem={errors.convenioPlanos}
                                    label={"Planos"}
                                    name={`convenioPlanos`}
                                    placeholder="Digite o plano e aperte enter"
                                    className={""}
                                    noResultsText={"Digite os planos"}
                                    viewMode={this.state.viewMode}
                                    value={values.convenioPlanos}
                                    creatable={true}
                                    required={values.planoObrigatorio}
                                    option={[]}
                                    multi
                                    apendRoot
                                    labelKey={"label"}
                                    valueKey={"plano"}
                                    defaultValue={
                                      this.state.viewMode &&
                                      values.convenioPlanos && (
                                        <React.Fragment>
                                          {values.convenioPlanos.map((item) => (
                                            <span className="tag">
                                              <span>{item.plano}</span>
                                            </span>
                                          ))}
                                        </React.Fragment>
                                      )
                                    }
                                  />
                                </FormGroup>
                              </div>
                            </React.Fragment>
                            <h6 class="w-100">
                              Regras de Procedimentos por tipo de Guia
                            </h6>
                            {values.listRegrasProcedimentoTipoGuia &&
                              values.listRegrasProcedimentoTipoGuia.map(
                                (regra, index) => {
                                  return (
                                    <React.Fragment>
                                      <div className="row w-100 mx-0">
                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label="Guia"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            options={
                                              objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                            }
                                            name={`listRegrasProcedimentoTipoGuia[${index}].tipoGuia`}
                                            searchable={true}
                                            returnFullObject={true}
                                            defaultValue={
                                              this.state.viewMode &&
                                                regra.tipoGuia &&
                                                regra.tipoGuia.id
                                                ? objectsConstants
                                                  .TIPOS_GUIA_REGRA_CONVENIO[
                                                  regra.tipoGuia.id
                                                ].nome
                                                : true
                                            }
                                            value={regra.tipoGuia}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                            apendRoot={true}
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={
                                              "Procedimento com quantidade automática"
                                            }
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listRegrasProcedimentoTipoGuia[${index}].procedimentos`}
                                            placeholder="Digite o código"
                                            type={"text"}
                                            value={regra.procedimentos}
                                            clearable={true}
                                            defaultValue={
                                              regra.procedimentos
                                                ? regra.procedimentos.map(
                                                  (procedimento, index) => {
                                                    let value =
                                                      procedimento.codigo;
                                                    if (index > 0) {
                                                      value = ", " + value;
                                                    }
                                                    return value;
                                                  }
                                                )
                                                : ""
                                            }
                                            id={`listRegrasProcedimentoTipoGuia[${index}].procedimentos`}
                                            labelKey={"codigo"}
                                            valueKey={"codigo"}
                                            returnFullObject={true}
                                            onFetchData={
                                              procedimentoService.find
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label={"Quantidade"}
                                            component={FormInput}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            id={`listRegrasProcedimentoTipoGuia${index}quantidade`}
                                            name={`listRegrasProcedimentoTipoGuia[${index}].quantidade`}
                                            viewMode={this.state.viewMode}
                                            type={"number"}
                                            placeholder=""
                                            className={""}
                                            value={regra.quantidade}
                                            erroMensagem={_.get(
                                              errors,
                                              `listRegrasProcedimentoTipoGuia[${index}].quantidade`
                                            )}
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-15">
                                          <InputViewEdit
                                            label={"Acomodação"}
                                            component={FormSelectInput}
                                            parent={{
                                              idConvenio: values.id,
                                              tipoGuia: regra.tipoGuia && regra.tipoGuia.id ? regra.tipoGuia.id : "",
                                            }}
                                            service={
                                              enumService.findAcomodacoesPorTipoGuiaAndConvenioAndCooperativa
                                            }
                                            className={"destaque"}
                                            id={`listRegrasProcedimentoTipoGuia${index}acomodacao`}
                                            name={`listRegrasProcedimentoTipoGuia[${index}].acomodacao`}
                                            returnFullObject={true}
                                            multi={false}
                                            isMulti={false}
                                            erroMensagem={_.get(
                                              errors,
                                              `listRegrasProcedimentoTipoGuia${index}.acomodacao`
                                            )}
                                            searchable={true}
                                            labelKey={"description"}
                                            valueKey={"name"}
                                            defaultValue={
                                              regra.acomodacao
                                                ? regra.acomodacao.description
                                                : "--"
                                            }
                                            value={regra.acomodacao}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                            clearable={true}
                                          />
                                        </FormGroup>

                                        <React.Fragment>
                                          <FormGroup className="central fixo mobile-100">
                                            {!this.state.viewMode && (
                                              <React.Fragment>
                                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                  {values
                                                    .listRegrasProcedimentoTipoGuia
                                                    .length > 1 && (
                                                      <button
                                                        className="btn-menos"
                                                        type="button"
                                                        onClick={() => {
                                                          let listRegrasProcedimentoTipoGuia =
                                                            _.cloneDeep(
                                                              values.listRegrasProcedimentoTipoGuia
                                                            );
                                                          listRegrasProcedimentoTipoGuia.splice(
                                                            index,
                                                            1
                                                          );
                                                          setFieldValue(
                                                            "listRegrasProcedimentoTipoGuia",
                                                            listRegrasProcedimentoTipoGuia
                                                          );
                                                        }}
                                                      ></button>
                                                    )}
                                                  <button
                                                    className="btn-mais"
                                                    type="button"
                                                    onClick={() => {
                                                      let listRegrasProcedimentoTipoGuia =
                                                        _.cloneDeep(
                                                          values.listRegrasProcedimentoTipoGuia
                                                        );
                                                      listRegrasProcedimentoTipoGuia.push(
                                                        {}
                                                      );
                                                      setFieldValue(
                                                        "listRegrasProcedimentoTipoGuia",
                                                        listRegrasProcedimentoTipoGuia
                                                      );
                                                    }}
                                                  ></button>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </FormGroup>
                                        </React.Fragment>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}

                            <h6 class="w-100">
                              Mensagem de Alerta Para o Campo de Atendimento por
                              Tipo de Guia
                            </h6>
                            {values.listRegraMensagemTipoGuia &&
                              values.listRegraMensagemTipoGuia.map(
                                (regraMensagemTipoGuia, index) => {
                                  return (
                                    <React.Fragment>
                                      <div className="row w-100 mx-0">
                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label="Guia"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            options={
                                              objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                            }
                                            name={`listRegraMensagemTipoGuia[${index}].tipoGuia`}
                                            id={`listRegraMensagemTipoGuia${index}tipoGuia`}
                                            searchable={true}
                                            returnFullObject={true}
                                            defaultValue={
                                              this.state.viewMode &&
                                                regraMensagemTipoGuia.tipoGuia &&
                                                regraMensagemTipoGuia.tipoGuia.id
                                                ? objectsConstants
                                                  .TIPOS_GUIA_REGRA_CONVENIO[
                                                  regraMensagemTipoGuia
                                                    .tipoGuia.id
                                                ].nome
                                                : true
                                            }
                                            value={
                                              regraMensagemTipoGuia.tipoGuia
                                            }
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                            apendRoot={true}
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-15">
                                          <InputViewEdit
                                            label="Campo de Atendimento Selecionado"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={true}
                                            name={`listRegraMensagemTipoGuia[${index}].campoAtendimentoTipoGuia`}
                                            placeholder="Digite o campo de atendimento"
                                            type={"text"}
                                            value={
                                              regraMensagemTipoGuia.campoAtendimentoTipoGuia
                                                ? regraMensagemTipoGuia.campoAtendimentoTipoGuia
                                                : ""
                                            }
                                            clearable={true}
                                            defaultValue={
                                              regraMensagemTipoGuia.campoAtendimentoTipoGuia &&
                                                regraMensagemTipoGuia
                                                  .campoAtendimentoTipoGuia
                                                  .campoAtendimento
                                                ? regraMensagemTipoGuia
                                                  .campoAtendimentoTipoGuia
                                                  .campoAtendimento.label
                                                : ""
                                            }
                                            id={`listRegraMensagemTipoGuia${index}campoAtendimentoTipoGuia`}
                                            searchable={true}
                                            labelKey={"campoAtendimento.label"}
                                            valueKey={"campoAtendimento.label"}
                                            returnFullObject={true}
                                            erroMensagem={_.get(
                                              errors,
                                              `campoAtendimentoTipoGuia`
                                            )}
                                            parent={
                                              regraMensagemTipoGuia.tipoGuia
                                            }
                                            onFetchData={
                                              campoAtendimentoService.find
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <InputViewEdit
                                            label={"Mensagem"}
                                            required={true}
                                            component={FormInput}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            name={`listRegraMensagemTipoGuia[${index}].mensagemAlerta`}
                                            id={`listRegraMensagemTipoGuia${index}mensagemAlerta`}
                                            placeholder=""
                                            type={"text"}
                                            viewMode={this.state.viewMode}
                                            value={_.get(
                                              values,
                                              `listRegraMensagemTipoGuia[${index}].mensagemAlerta`
                                            )}
                                            defaultValue={
                                              regraMensagemTipoGuia.mensagemAlerta
                                                ? regraMensagemTipoGuia.mensagemAlerta
                                                : ""
                                            }
                                            erroMensagem={_.get(
                                              errors,
                                              `listRegraMensagemTipoGuia[${index}].mensagemAlerta`
                                            )}
                                          />
                                        </FormGroup>

                                        <React.Fragment>
                                          <FormGroup className="central fixo mobile-100">
                                            {!this.state.viewMode && (
                                              <React.Fragment>
                                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                  {values
                                                    .listRegraMensagemTipoGuia
                                                    .length > 1 && (
                                                      <button
                                                        className="btn-menos"
                                                        type="button"
                                                        onClick={() => {
                                                          let listRegraMensagemTipoGuia =
                                                            _.cloneDeep(
                                                              values.listRegraMensagemTipoGuia
                                                            );
                                                          listRegraMensagemTipoGuia.splice(
                                                            index,
                                                            1
                                                          );
                                                          setFieldValue(
                                                            "listRegraMensagemTipoGuia",
                                                            listRegraMensagemTipoGuia
                                                          );
                                                        }}
                                                      ></button>
                                                    )}
                                                  <button
                                                    className="btn-mais"
                                                    type="button"
                                                    onClick={() => {
                                                      let listRegraMensagemTipoGuia =
                                                        _.cloneDeep(
                                                          values.listRegraMensagemTipoGuia
                                                        );
                                                      listRegraMensagemTipoGuia.push(
                                                        {}
                                                      );
                                                      setFieldValue(
                                                        "listRegraMensagemTipoGuia",
                                                        listRegraMensagemTipoGuia
                                                      );
                                                    }}
                                                  ></button>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </FormGroup>
                                        </React.Fragment>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}

                            <h6 class="w-100">
                              Regras de procedimento com preenchimento
                              automático por tipo de guia
                            </h6>
                            {values.listRegrasEspecialidadeProcedimentoTipoGuia &&
                              values.listRegrasEspecialidadeProcedimentoTipoGuia.map(
                                (regra, index) => {
                                  return (
                                    <React.Fragment>
                                      <div className="row w-100 mx-0">
                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label="Guia"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            options={
                                              objectsConstants.TIPOS_GUIA_REGRA_CONVENIO_SEM_HONORARIO
                                            }
                                            name={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].tipoGuia`}
                                            id={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].tipoGuia`}
                                            searchable={true}
                                            clearable={true}
                                            required={true}
                                            returnFullObject={true}
                                            defaultValue={
                                              this.state.viewMode &&
                                                regra.tipoGuia &&
                                                regra.tipoGuia.id
                                                ? objectsConstants
                                                  .TIPOS_GUIA_REGRA_CONVENIO_SEM_HONORARIO[
                                                  regra.tipoGuia.id
                                                ].nome
                                                : true
                                            }
                                            value={regra.tipoGuia}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                            apendRoot={true}
                                            erroMensagem={_.get(
                                              errors,
                                              `listRegrasEspecialidadeProcedimentoTipoGuia[${index}].tipoGuia`
                                            )}
                                          />
                                        </FormGroup>

                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={"Especialidade"}
                                            component={FormSelectInput}
                                            parent={values}
                                            placeholder="Digite a Especialidade"
                                            service={
                                              especialidadesService.findAll
                                            }
                                            className={"destaque"}
                                            id={`listRegrasEspecialidadeProcedimentoTipoGuia${index}especialidades`}
                                            name={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].especialidades`}
                                            returnFullObject={true}
                                            multi={true}
                                            isMulti={true}
                                            erroMensagem={_.get(
                                              errors,
                                              `listRegrasEspecialidadeProcedimentoTipoGuia${index}.espcialidades`
                                            )}
                                            searchable={true}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            defaultValue={
                                              regra.especialidades
                                                ? regra.especialidades.map(
                                                  (especialidade, index) => {
                                                    let value =
                                                      especialidade.nome;
                                                    if (index > 0) {
                                                      value = ", " + value;
                                                    }
                                                    return value;
                                                  }
                                                )
                                                : "--"
                                            }
                                            value={regra.especialidades}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                          />
                                        </FormGroup>

                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={"Procedimento"}
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            name={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].procedimentos`}
                                            placeholder="Digite o código"
                                            type={"text"}
                                            required={
                                              regra.tipoGuia !== null &&
                                                regra.tipoGuia !== undefined &&
                                                regra.tipoGuia.id !== null &&
                                                regra.tipoGuia.id !== undefined
                                                ? true
                                                : false
                                            }
                                            value={regra.procedimentos}
                                            clearable={true}
                                            defaultValue={
                                              regra.procedimentos
                                                ? regra.procedimentos.map(
                                                  (procedimento, index) => {
                                                    let value =
                                                      procedimento.codigo;
                                                    if (index > 0) {
                                                      value = ", " + value;
                                                    }
                                                    return value;
                                                  }
                                                )
                                                : ""
                                            }
                                            id={`listRegrasEspecialidadeProcedimentoTipoGuia${index}procedimentos`}
                                            labelKey={"codigo"}
                                            valueKey={"codigo"}
                                            returnFullObject={true}
                                            onFetchData={
                                              procedimentoService.find
                                            }
                                            erroMensagem={_.get(
                                              errors,
                                              `listRegrasEspecialidadeProcedimentoTipoGuia[${index}].procedimentos`
                                            )}
                                          />
                                        </FormGroup>

                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label="Via"
                                            component={FormSelectInput}
                                            service={viaAcessoService.findAll}
                                            className={"destaque"}
                                            name={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].viaAcesso`}
                                            id={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].viaAcesso`}
                                            defaultValue={
                                              regra.viaAcesso
                                                ? regra.viaAcesso.descricao
                                                : "--"
                                            }
                                            returnFullObject={true}
                                            searchable={true}
                                            labelKey={"descricao"}
                                            valueKey={"id"}
                                            value={regra.viaAcesso}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                          />
                                        </FormGroup>

                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label="Grau"
                                            component={FormSelectInput}
                                            service={grauService.findAll}
                                            className={"destaque"}
                                            name={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].grau`}
                                            id={`listRegrasEspecialidadeProcedimentoTipoGuia[${index}].grau`}
                                            defaultValue={
                                              regra.grau
                                                ? regra.grau.grau
                                                : "--"
                                            }
                                            returnFullObject={true}
                                            searchable={true}
                                            labelKey={"grau"}
                                            valueKey={"id"}
                                            value={regra.grau}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                          />
                                        </FormGroup>

                                        <React.Fragment>
                                          <FormGroup className="central fixo mobile-100">
                                            {!this.state.viewMode && (
                                              <React.Fragment>
                                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                  {values
                                                    .listRegrasEspecialidadeProcedimentoTipoGuia
                                                    .length > 1 && (
                                                      <button
                                                        className="btn-menos"
                                                        type="button"
                                                        onClick={() => {
                                                          let listRegrasEspecialidadeProcedimentoTipoGuia =
                                                            _.cloneDeep(
                                                              values.listRegrasEspecialidadeProcedimentoTipoGuia
                                                            );
                                                          listRegrasEspecialidadeProcedimentoTipoGuia.splice(
                                                            index,
                                                            1
                                                          );
                                                          setFieldValue(
                                                            "listRegrasEspecialidadeProcedimentoTipoGuia",
                                                            listRegrasEspecialidadeProcedimentoTipoGuia
                                                          );
                                                        }}
                                                      ></button>
                                                    )}
                                                  <button
                                                    className="btn-mais"
                                                    type="button"
                                                    onClick={() => {
                                                      let listRegrasEspecialidadeProcedimentoTipoGuia =
                                                        _.cloneDeep(
                                                          values.listRegrasEspecialidadeProcedimentoTipoGuia
                                                        );
                                                      listRegrasEspecialidadeProcedimentoTipoGuia.push(
                                                        {}
                                                      );
                                                      setFieldValue(
                                                        "listRegrasEspecialidadeProcedimentoTipoGuia",
                                                        listRegrasEspecialidadeProcedimentoTipoGuia
                                                      );
                                                    }}
                                                  ></button>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </FormGroup>
                                        </React.Fragment>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}

                            <h6 class="w-100">
                              Regras de hospitais por tipo de guia
                            </h6>
                            {values.listRegrasHospitalTipoGuia &&
                              values.listRegrasHospitalTipoGuia.map(
                                (regra, index) => {
                                  return (
                                    <React.Fragment>
                                      <div className="row w-100 mx-0">
                                        <FormGroup className="w-10">
                                          <InputViewEdit
                                            label="Guia"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            options={
                                              objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                            }
                                            name={`listRegrasHospitalTipoGuia[${index}].tipoGuia`}
                                            searchable={true}
                                            returnFullObject={true}
                                            defaultValue={
                                              this.state.viewMode &&
                                                regra.tipoGuia &&
                                                regra.tipoGuia.id
                                                ? objectsConstants
                                                  .TIPOS_GUIA_REGRA_CONVENIO[
                                                  regra.tipoGuia.id
                                                ].nome
                                                : true
                                            }
                                            value={regra.tipoGuia}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                            apendRoot={true}
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={
                                              "Hospitais que atendem o tipo de guia"
                                            }
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listRegrasHospitalTipoGuia[${index}].hospitalLocal`}
                                            placeholder="Digite o nome do hospital"
                                            type={"text"}
                                            value={regra.hospitalLocal}
                                            clearable={true}
                                            defaultValue={
                                              regra.hospitalLocal
                                                ? regra.hospitalLocal.map(
                                                  (hospital, index) => {
                                                    let nome = hospital.nome;
                                                    if (index > 0) {
                                                      nome = ", " + nome;
                                                    }
                                                    return nome;
                                                  }
                                                )
                                                : ""
                                            }
                                            id={`listRegrasHospitalTipoGuia[${index}].hospitalLocal`}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            returnFullObject={true}
                                            onFetchData={hospitalService.find}
                                          />
                                        </FormGroup>

                                        <React.Fragment>
                                          <FormGroup className="central fixo mobile-100">
                                            {!this.state.viewMode && (
                                              <React.Fragment>
                                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                  {values
                                                    .listRegrasHospitalTipoGuia
                                                    .length > 1 && (
                                                      <button
                                                        className="btn-menos"
                                                        type="button"
                                                        onClick={() => {
                                                          let listRegrasHospitalTipoGuia =
                                                            _.cloneDeep(
                                                              values.listRegrasHospitalTipoGuia
                                                            );
                                                          listRegrasHospitalTipoGuia.splice(
                                                            index,
                                                            1
                                                          );
                                                          setFieldValue(
                                                            "listRegrasHospitalTipoGuia",
                                                            listRegrasHospitalTipoGuia
                                                          );
                                                        }}
                                                      ></button>
                                                    )}
                                                  <button
                                                    className="btn-mais"
                                                    type="button"
                                                    onClick={() => {
                                                      let listRegrasHospitalTipoGuia =
                                                        _.cloneDeep(
                                                          values.listRegrasHospitalTipoGuia
                                                        );
                                                      listRegrasHospitalTipoGuia.push(
                                                        {}
                                                      );
                                                      setFieldValue(
                                                        "listRegrasHospitalTipoGuia",
                                                        listRegrasHospitalTipoGuia
                                                      );
                                                    }}
                                                  ></button>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </FormGroup>
                                        </React.Fragment>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}

                            <h6 class="w-100">
                              Regras de locais atendidos por tipo de guia
                            </h6>
                            {values.listRegrasEstabelecimentoTipoGuia &&
                              values.listRegrasEstabelecimentoTipoGuia.map(
                                (regra, index) => {
                                  return (
                                    <React.Fragment>
                                      <div className="row w-100 mx-0">
                                        <FormGroup className="fixo position-relative form-group">
                                          <InputViewEdit
                                            label="Guia não atendida pelo convênio"
                                            component={SelectInput}
                                            multi={false}
                                            isMulti={false}
                                            clearable={true}
                                            options={
                                              objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                            }
                                            name={`listRegrasEstabelecimentoTipoGuia[${index}].tipoGuia`}
                                            searchable={true}
                                            returnFullObject={true}
                                            defaultValue={
                                              this.state.viewMode &&
                                                regra.tipoGuia &&
                                                regra.tipoGuia.id
                                                ? objectsConstants
                                                  .TIPOS_GUIA_REGRA_CONVENIO[
                                                  regra.tipoGuia.id
                                                ].nome
                                                : true
                                            }
                                            value={regra.tipoGuia}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            viewMode={this.state.viewMode}
                                            onChange={(name, value) => {
                                              setFieldValue(name, value);
                                            }}
                                            apendRoot={true}
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={
                                              "Locais de atendimento que atendem o tipo de guia para o convênio"
                                            }
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listRegrasEstabelecimentoTipoGuia[${index}].estabelecimento`}
                                            placeholder="Digite o nome do consultório"
                                            type={"text"}
                                            value={regra.estabelecimento}
                                            clearable={true}
                                            defaultValue={
                                              regra.estabelecimento
                                                ? regra.estabelecimento.map(
                                                  (estabeleciment, index) => {
                                                    let nome =
                                                      estabeleciment.nome;
                                                    if (index > 0) {
                                                      nome = ", " + nome;
                                                    }
                                                    return nome;
                                                  }
                                                )
                                                : ""
                                            }
                                            id={`listRegrasEstabelecimentoTipoGuia[${index}].estabelecimento`}
                                            labelKey={"nome"}
                                            valueKey={"id"}
                                            returnFullObject={true}
                                            parent={{
                                              convenio: this.props.convenio,
                                            }}
                                            onFetchData={
                                              estabelecimentoService.find
                                            }
                                          />
                                        </FormGroup>

                                        <React.Fragment>
                                          <FormGroup className="central fixo mobile-100">
                                            {!this.state.viewMode && (
                                              <React.Fragment>
                                                <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                  {values
                                                    .listRegrasEstabelecimentoTipoGuia
                                                    .length > 1 && (
                                                      <button
                                                        className="btn-menos"
                                                        type="button"
                                                        onClick={() => {
                                                          let listRegrasEstabelecimentoTipoGuia =
                                                            _.cloneDeep(
                                                              values.listRegrasEstabelecimentoTipoGuia
                                                            );
                                                          listRegrasEstabelecimentoTipoGuia.splice(
                                                            index,
                                                            1
                                                          );
                                                          setFieldValue(
                                                            "listRegrasEstabelecimentoTipoGuia",
                                                            listRegrasEstabelecimentoTipoGuia
                                                          );
                                                        }}
                                                      ></button>
                                                    )}
                                                  <button
                                                    className="btn-mais"
                                                    type="button"
                                                    onClick={() => {
                                                      let listRegrasEstabelecimentoTipoGuia =
                                                        _.cloneDeep(
                                                          values.listRegrasEstabelecimentoTipoGuia
                                                        );
                                                      listRegrasEstabelecimentoTipoGuia.push(
                                                        {}
                                                      );
                                                      setFieldValue(
                                                        "listRegrasEstabelecimentoTipoGuia",
                                                        listRegrasEstabelecimentoTipoGuia
                                                      );
                                                    }}
                                                  ></button>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </FormGroup>
                                        </React.Fragment>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            <h6 class="w-100">
                              Regras de procedimentos que não podem ser
                              digitados em conjunto
                            </h6>
                            {values.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto &&
                              values.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto.map(
                                (regra, index) => {
                                  return (
                                    <div className="row w-100 mx-0">
                                      <React.Fragment>
                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={
                                              "Procedimentos que não podem ser digitados em conjunto"
                                            }
                                            component={SelectInput}
                                            multi={true}
                                            isMulti={true}
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto[${index}].procedimentos`}
                                            placeholder="Digite o código"
                                            type={"text"}
                                            value={regra.procedimentos}
                                            clearable={true}
                                            defaultValue={
                                              regra.procedimentos
                                                ? regra.procedimentos.map(
                                                  (procedimento, index) => {
                                                    let value =
                                                      procedimento.codigo;
                                                    if (index > 0) {
                                                      value = ", " + value;
                                                    }
                                                    return value;
                                                  }
                                                )
                                                : ""
                                            }
                                            id={`listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto${index}procedimentos`}
                                            erroMensagem={_.get(
                                              errors,
                                              `listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto[${index}].procedimentos`
                                            )}
                                            labelKey={"codigo"}
                                            valueKey={"codigo"}
                                            returnFullObject={true}
                                            onFetchData={
                                              procedimentoService.find
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup className="w-20">
                                          <InputViewEdit
                                            label={
                                              "Orientação de cobrança do procedimento"
                                            }
                                            onChange={(name, date) => {
                                              setFieldValue(name, date);
                                            }}
                                            component={FormInput}
                                            viewMode={this.state.viewMode}
                                            required={false}
                                            name={`listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto[${index}].orientacaoCobrancaProcedimento`}
                                            placeholder="Digite as orientações"
                                            type={"text"}
                                            value={regra.orientacaoCobrancaProcedimento}
                                            id={`listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto${index}orientacaoCobrancaProcedimento`}
                                            erroMensagem={_.get(
                                              errors,
                                              `listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto[${index}].orientacaoCobrancaProcedimento`
                                            )}
                                          />
                                        </FormGroup>
                                      </React.Fragment>
                                      <React.Fragment>
                                        <FormGroup className="central fixo mobile-100">
                                          {!this.state.viewMode && (
                                            <React.Fragment>
                                              <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                                {values
                                                  .listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto
                                                  .length > 1 && (
                                                    <button
                                                      className="btn-menos"
                                                      type="button"
                                                      onClick={() => {
                                                        let listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto =
                                                          _.cloneDeep(
                                                            values.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto
                                                          );
                                                        listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto.splice(
                                                          index,
                                                          1
                                                        );
                                                        setFieldValue(
                                                          "listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto",
                                                          listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto
                                                        );
                                                      }}
                                                    ></button>
                                                  )}
                                                <button
                                                  className="btn-mais"
                                                  type="button"
                                                  onClick={() => {
                                                    let listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto =
                                                      _.cloneDeep(
                                                        values.listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto
                                                      );
                                                    listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto.push(
                                                      {}
                                                    );
                                                    setFieldValue(
                                                      "listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto",
                                                      listaRegrasProcedimentosNaoPodemSerDigitadosEmConjunto
                                                    );
                                                  }}
                                                ></button>
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </FormGroup>
                                      </React.Fragment>
                                    </div>
                                  );
                                }
                              )}
                            <h6 class="w-100">
                              Criação automática de pendências
                            </h6>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Copiar senha para o campo guia?"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"copiarSenha"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.copiarSenha &&
                                    values.copiarSenha.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.copiarSenha &&
                                        values.copiarSenha.value
                                        ? values.copiarSenha.value
                                        : values.copiarSenha,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.copiarSenha)
                                    ? formatterHelper.getEnum(
                                      values.copiarSenha,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.copiarSenha
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Selecione o tipo de acomodação para enviar a guia de Consulta"
                                component={FormSelectInput}
                                service={enumService.findAcomodacoes}
                                className={"destaque"}
                                name={"acomodacaoPadraoGuiaConsulta"}
                                defaultValue={
                                  values.acomodacaoPadraoGuiaConsulta
                                    ? values.acomodacaoPadraoGuiaConsulta
                                      .description
                                    : "--"
                                }
                                returnFullObject={true}
                                searchable={true}
                                labelKey={"description"}
                                valueKey={"name"}
                                value={values.acomodacaoPadraoGuiaConsulta}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                              />
                            </FormGroup>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                label={"Procedimento com anexo Obrigatorio"}
                                component={SelectInput}
                                multi={true}
                                isMulti={true}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                viewMode={this.state.viewMode}
                                required={false}
                                name={`procedimentoCooperativaConvenioList`}
                                placeholder="Digite o código"
                                type={"text"}
                                value={
                                  values.procedimentoCooperativaConvenioList
                                }
                                clearable={true}
                                defaultValue={
                                  values.procedimentoCooperativaConvenioList
                                    ? values.procedimentoCooperativaConvenioList.map(
                                      (procedimento, index) => {
                                        let value = procedimento.codigo;
                                        if (index > 0) {
                                          value = ", " + value;
                                        }
                                        return value;
                                      }
                                    )
                                    : ""
                                }
                                id={`procedimentoCooperativaConvenioList`}
                                erroMensagem={_.get(
                                  errors,
                                  `procedimentoCooperativaConvenioList`
                                )}
                                labelKey={"codigo"}
                                valueKey={"codigo"}
                                returnFullObject={true}
                                onFetchData={procedimentoService.find}
                              />
                            </FormGroup>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                component={FormInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                id={`prazoDiasRetorno`}
                                label={"Prazo de consulta de retorno"}
                                name={`prazoDiasRetorno`}
                                placeholder=""
                                className={""}
                                viewMode={this.state.viewMode}
                                type={"number"}
                                value={values.prazoDiasRetorno}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Validar prazo de Reconsulta para a guia SADT"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={`validarReconsultaSadt`}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.validarReconsultaSadt &&
                                    values.validarReconsultaSadt == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.validarReconsultaSadt &&
                                        values.validarReconsultaSadt.value
                                        ? values.validarReconsultaSadt.value
                                        : values.validarReconsultaSadt,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.validarReconsultaSadt)
                                    ? formatterHelper.getEnum(
                                      values.validarReconsultaSadt,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.validarReconsultaSadt
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Habilitar rotina de validação de reconsulta"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={`habilitarRotinaValidacaoReconsulta`}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.habilitarRotinaValidacaoReconsulta &&
                                    values.habilitarRotinaValidacaoReconsulta == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.habilitarRotinaValidacaoReconsulta &&
                                        values.habilitarRotinaValidacaoReconsulta.value
                                        ? values.habilitarRotinaValidacaoReconsulta.value
                                        : values.habilitarRotinaValidacaoReconsulta,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.habilitarRotinaValidacaoReconsulta)
                                    ? formatterHelper.getEnum(
                                      values.habilitarRotinaValidacaoReconsulta,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.habilitarRotinaValidacaoReconsulta
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>

                            <FormGroup className="w-15">
                              <InputViewEdit
                                component={SelectInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                id={`tipoCarteirinha`}
                                label={"Carteirinha"}
                                options={
                                  objectsConstants.TIPOS_VALIDACAO_CARTEIRINHA
                                }
                                name={`tipoCarteirinha`}
                                placeholder=""
                                defaultValue={_.get(
                                  values.tipoCarteirinha,
                                  "description"
                                )}
                                className={""}
                                viewMode={this.state.viewMode}
                                returnFullObject={true}
                                searchable={true}
                                labelKey={"description"}
                                valueKey={"name"}
                                value={values.tipoCarteirinha}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <div className="quebra"></div>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                component={FormInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                id={`quantMinDigitosCarteirinha`}
                                label={
                                  "Quantidade mínima de digitos de carteirinha"
                                }
                                name={`quantMinDigitosCarteirinha`}
                                placeholder=""
                                className={""}
                                viewMode={this.state.viewMode}
                                type={"number"}
                                value={values.quantMinDigitosCarteirinha}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                component={FormInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                id={`quantMaxDigitosCarteirinha`}
                                label={
                                  "Quantidade máxima de digitos de carteirinha"
                                }
                                name={`quantMaxDigitosCarteirinha`}
                                placeholder=""
                                className={""}
                                viewMode={this.state.viewMode}
                                type={"number"}
                                value={values.quantMaxDigitosCarteirinha}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Validade obrigatória?"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"validadeCarteira"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.validadeCarteira &&
                                    values.validadeCarteira.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.validadeCarteira &&
                                        values.validadeCarteira.value
                                        ? values.validadeCarteira.value
                                        : values.validadeCarteira,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.validadeCarteira)
                                    ? formatterHelper.getEnum(
                                      values.validadeCarteira,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.validadeCarteira
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Validar carteirinha vencida?"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"validarCarteirinhaVencida"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.validarCarteirinhaVencida &&
                                    values.validarCarteirinhaVencida.value === false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.validarCarteirinhaVencida &&
                                        values.validarCarteirinhaVencida.value
                                        ? values.validarCarteirinhaVencida.value
                                        : values.validarCarteirinhaVencida,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.validarCarteirinhaVencida)
                                    ? formatterHelper.getEnum(
                                      values.validarCarteirinhaVencida,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.validarCarteirinhaVencida
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                component={CreatableMulti}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                id={`caracteresInicioCarteirinha`}
                                erroMensagem={errors.caracteresInicioCarteirinha}
                                label={"Caracteres de início da carteirinha"}
                                name={`listCaracteresInicioCarteirinha`}
                                placeholder="--"
                                className={""}
                                noResultsText={"Digite os caracteres:"}
                                viewMode={this.state.viewMode}
                                value={values.listCaracteresInicioCarteirinha}
                                creatable={true}
                                required={false}
                                option={[]}
                                multi
                                apendRoot
                                labelKey={"label"}
                                valueKey={"caracteres"}
                                defaultValue={
                                  this.state.viewMode && values.listCaracteresInicioCarteirinha &&
                                  <React.Fragment>
                                    {values.listCaracteresInicioCarteirinha.map(item => <span className="tag"><span>{item.caracteres}</span></span>)}
                                  </React.Fragment>
                                }
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Numero da guia obrigatória"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"numeroGuiaObrigatoria"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.numeroGuiaObrigatoria &&
                                    values.numeroGuiaObrigatoria.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.numeroGuiaObrigatoria &&
                                        values.numeroGuiaObrigatoria.value
                                        ? values.numeroGuiaObrigatoria.value
                                        : values.numeroGuiaObrigatoria,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.numeroGuiaObrigatoria)
                                    ? formatterHelper.getEnum(
                                      values.numeroGuiaObrigatoria,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.numeroGuiaObrigatoria
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  this.setState({
                                    habilitarReiniciarNumeroGuia: value.value,
                                  });
                                  if (value.value) {
                                    _.set(
                                      values,
                                      `reiniciarnumeroGuiaAno`,
                                      null
                                    );
                                  }
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Reiniciar número de guia por ano"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"reiniciarnumeroGuiaAno"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.reiniciarnumeroGuiaAno &&
                                    values.reiniciarnumeroGuiaAno.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.reiniciarnumeroGuiaAno &&
                                        values.reiniciarnumeroGuiaAno.value
                                        ? values.reiniciarnumeroGuiaAno.value
                                        : values.reiniciarnumeroGuiaAno,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.reiniciarnumeroGuiaAno)
                                    ? formatterHelper.getEnum(
                                      values.reiniciarnumeroGuiaAno,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.reiniciarnumeroGuiaAno
                                }
                                disabled={
                                  this.state.habilitarReiniciarNumeroGuia
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Valor da Consulta"
                                component={MoneyInput}
                                name={"valorConsulta"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={values.valorConsulta}
                                value={values.valorConsulta}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                label={"Procedimento padrão guia SADT"}
                                component={SelectInput}
                                onChange={(name, date) => {
                                  setFieldValue(
                                    `procedimentoPadraoGuiaSadt`,
                                    date
                                  );
                                }}
                                viewMode={this.state.viewMode}
                                required={false}
                                name={`procedimentoPadraoGuiaSadt`}
                                placeholder="Digite o código"
                                type={"text"}
                                value={values.procedimentoPadraoGuiaSadt}
                                clearable={true}
                                defaultValue={
                                  values.procedimentoPadraoGuiaSadt
                                    ? values.procedimentoPadraoGuiaSadt.codigo
                                    : ""
                                }
                                id={`procedimentoPadraoGuiaSadt`}
                                erroMensagem={_.get(
                                  errors,
                                  `procedimentoPadraoGuiaSadt`
                                )}
                                labelKey={"codigo"}
                                valueKey={"codigo"}
                                returnFullObject={true}
                                onFetchData={procedimentoService.find}
                              />
                            </FormGroup>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Validar duplicidade de atendimentos"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"validarDuplicidadeAtendimentos"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.validarDuplicidadeAtendimentos &&
                                    values.validarDuplicidadeAtendimentos.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.validarDuplicidadeAtendimentos &&
                                        values.validarDuplicidadeAtendimentos.value
                                        ? values.validarDuplicidadeAtendimentos.value
                                        : values.validarDuplicidadeAtendimentos,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.validarDuplicidadeAtendimentos)
                                    ? formatterHelper.getEnum(
                                      values.validarDuplicidadeAtendimentos,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.validarDuplicidadeAtendimentos
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>

                            <div className="quebra"></div>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Complemento Obrigatório"
                                component={SelectInput}
                                multi={true}
                                isMulti={true}
                                options={
                                  objectsConstants.TIPOS_GUIA_REGRA_CONVENIO
                                }
                                name={"tiposGuiaProduto"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  this.state.viewMode &&
                                  values.tiposGuiaProduto && (
                                    <React.Fragment>
                                      {values.tiposGuiaProduto.map((item) => (
                                        <span className="tag">
                                          <span>
                                            {
                                              objectsConstants
                                                .TIPOS_GUIA_REGRA_CONVENIO[
                                                item.id
                                              ].nome
                                            }
                                          </span>
                                        </span>
                                      ))}
                                    </React.Fragment>
                                  )
                                }
                                value={values.tiposGuiaProduto}
                                labelKey={"nome"}
                                valueKey={"id"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Bloquear convênio para consultorio"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"bloquearConsultorio"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.bloquearConsultorio &&
                                    values.bloquearConsultorio.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.bloquearConsultorio &&
                                        values.bloquearConsultorio.value
                                        ? values.bloquearConsultorio.value
                                        : values.bloquearConsultorio,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.bloquearConsultorio)
                                    ? formatterHelper.getEnum(
                                      values.bloquearConsultorio,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.bloquearConsultorio
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>

                            <FormGroup className="w-20">
                              <InputViewEdit
                                component={FormInput}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                id={`prazoGeracaoProtocolo`}
                                label={"Prazo para geração de protocolo"}
                                name={`prazoGeracaoProtocolo`}
                                placeholder=""
                                className={""}
                                viewMode={this.state.viewMode}
                                type={"number"}
                                value={values.prazoGeracaoProtocolo}
                              />
                            </FormGroup>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label="Faturamento Particular"
                                component={SelectInput}
                                options={objectsConstants.SIM_NAO}
                                name={"faturamentoParticular"}
                                searchable={true}
                                returnFullObject={true}
                                defaultValue={
                                  values.faturamentoParticular &&
                                    values.faturamentoParticular.value == false
                                    ? "Não"
                                    : formatterHelper.getLabelEnum(
                                      values.faturamentoParticular &&
                                        values.faturamentoParticular.value
                                        ? values.faturamentoParticular.value
                                        : values.faturamentoParticular,
                                      objectsConstants.SIM_NAO
                                    )
                                }
                                value={
                                  _.isBoolean(values.faturamentoParticular)
                                    ? formatterHelper.getEnum(
                                      values.faturamentoParticular,
                                      objectsConstants.SIM_NAO
                                    )
                                    : values.faturamentoParticular
                                }
                                labelKey={"label"}
                                valueKey={"value"}
                                viewMode={this.state.viewMode}
                                onChange={(name, value) => {
                                  setFieldValue(name, value.value);
                                }}
                                apendRoot={true}
                              />
                            </FormGroup>

                            <div className="quebra"></div>
                            <FormGroup className="w-20">
                              <InputViewEdit
                                label={
                                  "Instruções para inserção do atendimento"
                                }
                                component={FormTextCKEditor}
                                onChange={(name, value) => {
                                  setFieldValue(name, value);
                                }}
                                viewMode={this.state.viewMode}
                                required={false}
                                name={`instrucoesAtendimento`}
                                placeholder="Digite as instruções para atendimento"
                                type={"text"}
                                value={values.instrucoesAtendimento}
                                clearable={true}
                                defaultValue={
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: values.instrucoesAtendimento
                                        ? values.instrucoesAtendimento
                                        : "",
                                    }}
                                  ></div>
                                }
                                id={`instrucoesAtendimento`}
                                erroMensagem={_.get(
                                  errors,
                                  `instrucoesAtendimento`
                                )}
                              />
                            </FormGroup>
                          </div>
                        </React.Fragment>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <MenuHistorico
                  titulo={this.props.convenio && this.props.convenio.nome}
                  subTitulo={"Regras"}
                  listaHistoricos={this.state.listaHistoricos}
                  toggleHistorico={this.toggleHistorico}
                  openMenuHistorico={this.state.openMenuHistorico}
                />
              </form>
            )
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  load: { loading },
  alert: { success, error, clear },
}) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(RegrasConveniosForm));
