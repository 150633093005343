import * as Yup from "yup";
import FiltroAtendimentoCooperadoConsultorio from "../views/relatorios/atendimentoCooperadoConsultorio/FiltroAtendimentoCooperadoConsultorio";
import FiltroRelatorioGuiasConsultas from "../views/relatorios/extracaoDadosGuiasConsultas/FiltroRelatorioGuiasConsultas";
import FiltroRelatorioGuiasRecusadas from "../views/relatorios/guiasRecusadas/FiltroRelatorioGuiasRecusadas";
import FiltroRelatorioMonitoramentoMovimentacaoGuia from "../views/relatorios/monitoramentoMovimentacaoGuia/FiltroRelatorioMonitoramentoMovimentacaoGuia";
import DashRegiaoEspecialidade from "../views/relatorios/quantidadeRegiaoEspecialidade/DashRegiaoEspecialidade";
import FiltroRelatorioEncaminhamentoPorStatus from "../views/relatorios/statusEncaminhamento/FiltroRelatorioEncaminhamentoPorStatus";
import FiltroMapaCirurgico from "../views/relatorios/mapaCirurgico/FiltroMapaCirurgico";
const moment = require('moment');
//const dataLimiteInicio = moment().subtract(90, 'days');
//const dataLimiteFim = moment().add(90, 'days');


export const relatorios = [
  {
    Filtro: FiltroRelatorioEncaminhamentoPorStatus,
    yup: Yup.object().shape({
      //dataInicioEncaminhamento: Yup.date().required("Obrigatório"),
      dataFimEncaminhamento: Yup.date().test('dataFimEncaminhamento', 'Obrigatório', function () {
        const { parent, createError } = this;
        if (!parent.dataFimEncaminhamento) {
          return createError();
        } else if (((parent.dataFimEncaminhamento - parent.dataInicioEncaminhamento) / (1000 * 60 * 60 * 24)) > 180) {
          return createError({
            path: "dataFimEncaminhamento",
            message: 'O intervalo das datas deve ser no máximo 180 dias'
          });
        } else {
          return true;
        }
      }),
      dataInicioEncaminhamento: Yup.date().test('dataInicioEncaminhamento', 'Obrigatório', function () {
        const { parent, createError } = this;
        if (!parent.dataInicioEncaminhamento) {
          return createError();
        } else if (((parent.dataFimEncaminhamento - parent.dataInicioEncaminhamento) / (1000 * 60 * 60 * 24)) > 180) {
          return createError({
            path: "dataInicioEncaminhamento",
            message: 'O intervalo das datas deve ser no máximo 180 dias'
          });
        } else {
          return true;
        }
      })
    }),
    controller: "encaminhamentosPorData",
    nome: "Status de encaminhamento ",
    isAdmin: true
  }
  ,
  {
    Filtro: FiltroRelatorioGuiasRecusadas,
    yup: Yup.object().shape({
      dataInicio: Yup.date().required("Obrigatório"),
      dataFim: Yup.date().required("Obrigatório")

    }),
    controller: "guiasExcluidas",
    nome: "Guias excluidas"
  },
  {
    Filtro: FiltroRelatorioMonitoramentoMovimentacaoGuia,
    yup: Yup.object().shape({
      dataInicio: Yup.date().required("Obrigatório"),
      dataFim: Yup.date().required("Obrigatório")

    }),
    controller: "monitoramentoMovimentacaoGuia",
    nome: "Monitoramento da Movimentação da Guia"
  },
  {
    Filtro: null,
    yup: Yup.object().shape({}),
    controller: "statusExames",
    nome: "Relatório de exames"
  },
  {
    Filtro: FiltroAtendimentoCooperadoConsultorio,
    yup: Yup.object().shape({
      dataInicio: Yup.date()
        .max(
          Yup.ref("dataFim"),
          "A data de início não pode ser posterior à data de término"
        )
        .test("validacaoDiferencaDatas", null, function (value) {
          const { dataInicio, dataFim } = this.parent;
          const diffInDays = Math.ceil(
            (new Date(dataFim) - new Date(dataInicio)) / (1000 * 60 * 60 * 24)
          );
          if (diffInDays > 90) {
            return this.createError({
              message: "A diferença entre as datas não pode ser superior a 90 dias",
              path: "dataFim",
            });
          }
          return true;
        })
    }),
    controller: "atendimentoCooperadoConsultorio",
    nome: "Atendimento por cooperado e consultório"
  },

  {
    Filtro: DashRegiaoEspecialidade,
    yup: Yup.object().shape({
      dataFim: Yup.date().test('dataFim', 'Obrigatório', function () {
        const { parent, createError } = this;
        if (!parent.dataFim) {
          return createError();
        } else if (((parent.dataFim - parent.dataInicio) / (1000 * 60 * 60 * 24)) > 90) {
          return createError({
            path: "dataFim",
            message: 'O intervalo das datas deve ser no máximo 90 dias'
          });
        } else {
          return true;
        }
      }),
      dataInicio: Yup.date().test('dataInicio', 'Obrigatório', function () {
        const { parent, createError } = this;
        if (!parent.dataInicio) {
          return createError();
        } else if (((parent.dataFim - parent.dataInicio) / (1000 * 60 * 60 * 24)) > 90) {
          return createError({
            path: "dataInicio",
            message: 'O intervalo das datas deve ser no máximo 90 dias'
          });
        } else {
          return true;
        }
      })
    }),
    controller: "quantRegiaoEspecialidade",
    nome: "Quantidade de atendimentos por região e especialidade"
  },
  {
    Filtro: FiltroRelatorioGuiasConsultas,
    yup: Yup.object().shape({
      //dataInicio:Yup.date().required("Obrigatório"),
      dataInicio: Yup.date().test('dataInicio', 'Obrigatório', function () {
        const { parent, createError } = this;
        if (!parent.dataFim) {
          return createError();
        } else if (((parent.dataFim - parent.dataInicio) / (1000 * 60 * 60 * 24)) > 180) {
          return createError({
            path: "dataInicio",
            message: 'O intervalo das datas deve ser no máximo 180 dias'
          });
        } else {
          return true;

        }


      }),
      //dataFim:Yup.date().required("Obrigatório")
      dataFim: Yup.date().test('dataFim', 'Obrigatório', function () {
        const { parent, createError } = this;
        if (!parent.dataInicio) {
          return createError();
        } else if (((parent.dataFim - parent.dataInicio) / (1000 * 60 * 60 * 24)) > 180) {
          return createError({
            path: "dataFim",
            message: 'O intervalo das datas deve ser no máximo 180 dias'
          });
        } else {
          return true;
        }
      })

    }),
    controller: "guiasConsultas",
    nome: "Extração de Dados de Consultas"
  },

  {
    Filtro: FiltroMapaCirurgico,
    yup: Yup.object().shape({
      dataInicio: Yup.date()
        .max(
          Yup.ref("dataFim"),
          "A data de início não pode ser posterior à data de término"
        )
        .test("validacaoDiferencaDatas", null, function (value) {
          const { dataInicio, dataFim } = this.parent;
          const diffInDays = Math.ceil(
            (new Date(dataFim) - new Date(dataInicio)) / (1000 * 60 * 60 * 24)
          );
          if (diffInDays > 90) {
            return this.createError({
              message: "A diferença entre as datas não pode ser superior a 90 dias",
              path: "dataFim",
            });
          }
          return true;
        })
    }),
    controller: "mapaCirurgico",
    nome: "Mapa Cirúrgico"
  },

];