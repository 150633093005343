//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import { objectsConstants } from "../constants/objects.constants";

export const hospitalService = {
  findAll,
  find,
  findHospitalConvenioPorTipoGuia,
  findHospitaisRegraConvenio
};


function findAll(object) {
  return defaultService.doGet(`${urlsConstants.HOSPITAL_LOCAL}findAll`);
}

function find(hospital) {
  return defaultService.doGet(`${urlsConstants.HOSPITAL_LOCAL}${hospital}`);
}

function findHospitalConvenioPorTipoGuia(atendimento){
  let type;
  for(let i=0; i< objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length;i++){
    if(objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label===atendimento.type){
     type = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
    }
  }
  if(atendimento&&atendimento.convenio&&atendimento.type) {
    return defaultService.doGet(`${urlsConstants.HOSPITAL_LOCAL}findByConvenioAndTipoGuia/${atendimento.convenio.id}/${type}`);
  }
  
}

function findHospitaisRegraConvenio(idConvenio,tipoGuia){
  let type;
  for(let i=0; i< objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length;i++){
    if(objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label===tipoGuia){
     type = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
    }
  }
  if(idConvenio&&tipoGuia) {
    return defaultService.doGet(`${urlsConstants.HOSPITAL_LOCAL}findHospitaisRegraConvenio/${idConvenio}/${type}`);
  }
}


